import { createSlice } from '@reduxjs/toolkit'
import { ActionWithPayload, ReducerConfig, AppState } from './store'

export interface OrderModel {
  name?: string
  email?: string
  address1?: string
  address2?: string
  phoneNumber?: string
  otherInfo?: string
  paymentMethod?: string
  isSubmitting: boolean
  error?: Error
}

export interface SetInfoPayload {
  name?: string
  email?: string
  address1?: string
  address2?: string
  phoneNumber?: string
  otherInfo?: string
  paymentMethod?: string
}

const initialState: OrderModel = {
  isSubmitting: false,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setInfo: (state, action: ActionWithPayload<SetInfoPayload>) => {
      state.name = action.payload.name ?? state.name
      state.email = action.payload.email ?? state.email
      state.phoneNumber = action.payload.phoneNumber ?? state.phoneNumber
      state.address1 = action.payload.address1 ?? state.address1
      state.address2 = action.payload.address2 ?? state.address2
      state.otherInfo = action.payload.otherInfo ?? state.otherInfo
      state.paymentMethod = action.payload.paymentMethod ?? state.paymentMethod
    },
    isSubmittingOrder: (state, action: ActionWithPayload<void>) => {
      state.isSubmitting = true
    },
    orderSubmitted: (state, action: ActionWithPayload<void>) => {
      state.isSubmitting = false
    },
    orderSubmitErrored: (state, action: ActionWithPayload<Error>) => {
      state.isSubmitting = false
      state.error = action.payload
    },
  },
})

export const selectIsSubmitting = (state: AppState) =>
  state?.order?.isSubmitting
export const selectName = (state: AppState) => state?.order?.name
export const selectEmail = (state: AppState) => state?.order?.email
export const selectAddress1 = (state: AppState) => state?.order?.address1
export const selectAddress2 = (state: AppState) => state?.order?.address2
export const selectPhoneNumber = (state: AppState) => state?.order?.phoneNumber
export const selectOtherInfo = (state: AppState) => state?.order?.otherInfo

export const {
  setInfo,
  isSubmittingOrder,
  orderSubmitted,
  orderSubmitErrored,
} = orderSlice.actions
export const orderReducerConfig: ReducerConfig<OrderModel> = {
  name: orderSlice.name,
  reducer: orderSlice.reducer,
}
