import React from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import './App.scss'
import { MenuBar } from './components/MenuBar/MenuBar'
import { Drawer } from './components/Drawer/Drawer'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Checkout } from './components/Checkout/Checkout'
import { Home } from './components/Home/Home'
import { Provider } from 'react-redux'
import { store } from './services/store'
import { Department } from './components/Department/Department'
import { OrderComplete } from './components/OrderComplete/OrderComplete'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { AppFooter } from './components/AppFooter/AppFooter'
import translationEn from './i18n/en.json'
import translationFr from './i18n/fr.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      fr: {
        translation: translationFr,
      },
    },
    lng: 'fr',
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false,
    },
  })

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#105b32',
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <MenuBar />
          <Drawer />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/department/:departmentId">
              <Department />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <Route path="/completed">
              <OrderComplete />
            </Route>
          </Switch>
          <AppFooter />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  )
}

export default App
