import React from 'react'
import MaterialDrawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import { selectIsDrawerOpen, closeDrawer } from '../../services/modals.slice'
import { useSelector, useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ALL_DEPARTMENTS } from '../../services/departments'

export const Drawer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsDrawerOpen)
  const onCloseDrawer = () => dispatch(closeDrawer())
  const { t, i18n } = useTranslation()
  const onClickEnglish = () => i18n.changeLanguage('en')
  const onClickFrench = () => i18n.changeLanguage('fr')
  const allDepartments = ALL_DEPARTMENTS

  return (
    <MaterialDrawer
      anchor="left"
      open={isOpen}
      onClose={(event, reason) => onCloseDrawer()}
    >
      <img
        src={'/mduvil-logo.png'}
        alt="Marche du Village - CDN"
        style={{ width: 250 }}
      />
      <List>
        <ListItem button component={NavLink} to={`/`}>
          <ListItemText>{t('Home')}</ListItemText>
        </ListItem>
        {allDepartments.map((department) => (
          <ListItem
            button
            component={NavLink}
            to={`/department/${department.id}`}
            key={department.id}
          >
            <ListItemText>
              {i18n.language === 'en'
                ? department.departmentNameEn
                : department.departmentNameFr}
            </ListItemText>
          </ListItem>
        ))}
      </List>
      <div style={{ flexGrow: 1 }} />
      <div>
        <Button onClick={onClickFrench}>French</Button>
        <Button onClick={onClickEnglish}>English</Button>
      </div>
    </MaterialDrawer>
  )
}
