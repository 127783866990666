import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useHomeStyles } from './Home.styles'
import { useTranslation } from 'react-i18next'

export interface HomeProps {}

export const Home: React.FunctionComponent<HomeProps> = (props) => {
  const classes = useHomeStyles()
  const { t } = useTranslation()
  return (
    <>
      <main className={classes.root}>
        <img
          src="/store-front.png"
          alt={t('Store front')}
          className={classes.headerImage}
        />
        <Typography variant="h3" noWrap className={classes.heading}>
          {t('Welcome')}
        </Typography>
        <Typography variant="subtitle1" className={classes.body}>
          {t('Dear customers')}
        </Typography>
        <Typography variant="subtitle1" className={classes.body}>
          {t('To place an online order')}
        </Typography>
      </main>
    </>
  )
}
