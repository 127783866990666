import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useCounterControlsStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      maxWidth: '43px',
      '& input': { textAlign: 'center' },
      '& ::-webkit-inner-spin-button': {
        display: 'none',
      },
    },
  })
)
