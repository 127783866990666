import React, { FunctionComponent, MouseEvent, MutableRefObject } from 'react'

import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { useSearchCardStyles } from './SearchCard.styles'
import { ALL_ARTICLES } from '../../services/article'
import { ListCartItem } from '../ListCartItem/ListCartItem'
import {
  selectIsSearchCardOpen,
  closeSearchCard,
} from '../../services/modals.slice'
import { useSelector, useDispatch } from 'react-redux'

export interface SearchCardProps {
  query: string
  searchInputRef: MutableRefObject<HTMLInputElement | null>
}

export const SearchCard: FunctionComponent<SearchCardProps> = ({
  query,
  searchInputRef,
}) => {
  const classes = useSearchCardStyles()
  const dispatch = useDispatch()
  const isSearchCardOpen = useSelector(selectIsSearchCardOpen)
  if (!query.length || !isSearchCardOpen) {
    return null
  }
  const searchableQuery = query.toLowerCase()
  const foundItems = ALL_ARTICLES.filter(
    (article) =>
      article.nameEn.toLowerCase().includes(searchableQuery) ||
      article.nameFr.toLowerCase().includes(searchableQuery)
  )
    .map((article) => ({ ...article, quantity: 0 }))
    .slice(0, 10)

  if (!foundItems.length) {
    return null
  }

  const onClickAway = (event: MouseEvent<Document>) => {
    try {
      if (!searchInputRef.current?.isSameNode(event.target as Node)) {
        dispatch(closeSearchCard())
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Paper elevation={3} className={classes.root}>
        <ListCartItem items={foundItems} />
      </Paper>
    </ClickAwayListener>
  )
}
