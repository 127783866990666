import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles'

export const useCheckoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: fade(theme.palette.common.white, 0.85),
      padding: '2em',
      paddingLeft: '4em',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    cart: {
      display: 'flex',
      flexDirection: 'column',
    },
    clientInfo: {
      display: 'inline-flex',
      flexDirection: 'column',
      marginTop: 10,
    },
    removeButton: {
      alignSelf: 'flex-end',
    },
    submitButton: {
      marginTop: 10,
      width: '100%',
    },
    paymentMethodGroup: {
      margin: 10,
    },
    paymentMethod: {
      display: 'inline-block',
      float: 'right',
      marginTop: 10,
      position: 'relative',
      width: '40%',
    },
    ccCheck: {
      marginLeft: 30,
    },
    '@media(max-width: 720px)': {
      paymentMethod: {
        display: 'block',
        right: '0',
        width: '100%',
        float: 'initial',
      },
    },
  })
)
