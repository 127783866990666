import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useSearchCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: '5em',
      left: '20em',
      zIndex: 99,
    },
    '@media(max-width: 600px)': {
      root: {
        left: 0,
        top: '4em',
      },
    },
  })
)
