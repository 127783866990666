import React, { FunctionComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import { useListCartItemStyles } from './ListCartItem.styles'

import { CartItem, updateInCart } from '../../services/cart.slice'
import { CounterControls } from '../CounterControls/CounterControls'
import { useDispatch } from 'react-redux'

export interface ListCartItemProps {
  items: readonly CartItem[]
}

export const ListCartItem: FunctionComponent<ListCartItemProps> = ({
  items,
}) => {
  const { i18n } = useTranslation()
  const classes = useListCartItemStyles()
  const dispatch = useDispatch()
  const onCounterValueChange = (itemId: string) => (quantity: number) =>
    dispatch(updateInCart({ articleId: itemId, quantity }))

  return (
    <ul className={classes.root}>
      {items.map((item) => (
        <li className={classes.listItem} key={item.id}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            className={classes.itemName}
          >
            {i18n.language === 'en' ? item.nameEn : item.nameFr}
          </Typography>
          <CounterControls
            startingValue={item.quantity}
            onCounterValueChange={onCounterValueChange(item.id)}
            unit={item.unit}
          />
        </li>
      ))}
    </ul>
  )
}
