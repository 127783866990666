import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { useTranslation } from 'react-i18next'
import { useCounterControlsStyles } from './CounterControls.styles'

export interface CounterControlsProps {
  startingValue?: number
  onCounterValueChange(counter: number): void
  unit?: string
}

export const CounterControls: FunctionComponent<CounterControlsProps> = ({
  startingValue = 1,
  onCounterValueChange,
  unit,
}) => {
  const { t } = useTranslation()
  const classes = useCounterControlsStyles()
  let [quantity, setQuantity] = useState(startingValue)
  useEffect(() => {
    setQuantity(startingValue)
  }, [startingValue])

  const updateQuantity = (newQuantity: number) => {
    setQuantity(newQuantity)
    onCounterValueChange(newQuantity)
  }

  const onAddClick = () => {
    updateQuantity(quantity + 1)
  }

  const onRemoveClick = () => {
    const decreasedByOne = quantity - 1
    updateQuantity(decreasedByOne < 0 ? 0 : decreasedByOne)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateQuantity(event.target.value === '' ? 0 : Number(event.target.value))
  }

  return (
    <span>
      <IconButton aria-label={t('decrease quantity')} onClick={onRemoveClick}>
        <RemoveIcon />
      </IconButton>
      <Input
        id="quantity-input"
        className={classes.input}
        value={quantity}
        margin="dense"
        onChange={handleInputChange}
        type="number"
      />
      <IconButton aria-label={t('increase quantity')} onClick={onAddClick}>
        <AddIcon />
      </IconButton>
      {unit && <label htmlFor="quantity-input">{unit}</label>}
    </span>
  )
}
