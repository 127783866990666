export interface Department {
  id: string
  departmentNameEn: string
  departmentNameFr: string
}

// TODO: Move them to a backend later
// prettier-ignore
export const ALL_DEPARTMENTS: readonly Department[] = [
  { id: '1', departmentNameEn: 'Fresh Vegetables', departmentNameFr: 'Légumes Frais' },
  { id: '10', departmentNameEn: 'Fresh Fruits', departmentNameFr: 'Fruits Frais' },
  { id: '2', departmentNameEn: 'Cheese', departmentNameFr: 'Fromage' },
  { id: '3', departmentNameEn: 'Meat', departmentNameFr: 'Viandes' },
  { id: '4', departmentNameEn: 'Fish and Seafood', departmentNameFr: 'Poissons et Fruits de Mer' },
  { id: '5', departmentNameEn: 'Deli Meat', departmentNameFr: 'Charcuterie' },
  { id: '6', departmentNameEn: 'Bakery', departmentNameFr: 'Boulangerie' },
  { id: '7', departmentNameEn: 'Ready-To-Eat', departmentNameFr: 'Prêt-à-manger' },
  { id: '8', departmentNameEn: 'Grocery items', departmentNameFr: 'Épicerie' },
  { id: '9', departmentNameEn: 'Alcoholic Beverage', departmentNameFr: 'Boissons alcoolisées' },
  { id: '11', departmentNameEn: 'Organic Product', departmentNameFr: 'Produits Biologique' },
  { id: '12', departmentNameEn: 'Beverage', departmentNameFr: 'Boissons' },
  { id: '13', departmentNameEn: 'Refrigerated Grocery', departmentNameFr: 'Produits refrigeres' },
]
