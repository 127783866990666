import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'

import { useItemStyles } from './Item.styles'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../services/cart.slice'
import { CounterControls } from '../CounterControls/CounterControls'

export interface ItemProps {
  articleId: string
  articleName: string
  quantityUnit?: string
  imageUrl?: string
  types?: readonly string[]
}

export const Item: React.FunctionComponent<ItemProps> = ({
  articleId,
  articleName,
  quantityUnit,
  imageUrl = '',
}) => {
  const classes = useItemStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const onAddToCart: () => void = () => {
    dispatch(
      addToCart({
        articleId,
        quantity,
      })
    )
  }

  return (
    <Card className={classes.root}>
      <CardHeader title={articleName} className={classes.title} />
      <CardMedia
        className={classes.image}
        image={imageUrl}
        title={articleName}
        component="img"
      />
      <CardActions className={classes.actions}>
        <CounterControls
          onCounterValueChange={setQuantity}
          unit={quantityUnit}
        />
        <Button size="small" onClick={onAddToCart}>
          {t('Add to Cart')}
        </Button>
      </CardActions>
    </Card>
  )
}
