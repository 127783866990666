export interface Article {
  departmentId: string
  id: string
  nameEn: string
  nameFr: string
  imageUrl?: string
  unit?: string
}

// TODO: Move them to a backend later
// prettier-ignore
export const ALL_ARTICLES: readonly Article[] = [
  { departmentId: '1', id: '1', nameFr: 'Ail', nameEn: 'Garlic', imageUrl: '/assets/ail.jpg' },
  { departmentId: '1', id: '2', nameFr: 'Aneth', nameEn: 'Dill', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004891.jpg' },
  { departmentId: '1', id: '3', nameFr: 'Arichaut', nameEn: 'Artichoke', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004084.jpg' },
  { departmentId: '1', id: '4', nameFr: 'Asperges', nameEn: 'Asparagus', imageUrl: 'https://cdn.shopify.com/s/files/1/0336/7501/products/asparagus_5050c9b2-aeb9-4a91-a816-2dff9421b16b_medium.jpg?v=1461779215' },
  { departmentId: '1', id: '5', nameFr: 'Bok Choy', nameEn: 'Bok Choy', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004544.jpg' },
  { departmentId: '1', id: '6', nameFr: 'Brocoli (Couronne)', nameEn: 'Broccoli (crown)', imageUrl: '/assets/BROCOLI.jpg' },
  { departmentId: '1', id: '7', nameFr: 'Bettraves (1 paquet)', nameEn: 'Beets (1 pack)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000094540.jpg' },
  { departmentId: '1', id: '8', nameFr: 'Bettrave rouge cuite sous vide', nameEn: 'Red Cooked Beetroot', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0327937011100.jpg' },
  { departmentId: '1', id: '9', nameFr: 'Carottes en sac (2 lb)', nameEn: 'Carrots (2 lb)', imageUrl: '/assets/CAROTTES.jpg' }, 
  { departmentId: '1', id: '10', nameFr: 'Carottes nantaise', nameEn: 'Nantes Carrots', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003338345005.jpg' },
  { departmentId: '1', id: '11', nameFr: 'Carottes nantaise couleur', nameEn: 'Color Nantes Carrots', imageUrl: 'https://www.marchestradition.com/wp-content/uploads/2017/11/carottes-nantaises-400x400-BS010601-pub75362.jpg' },
  { departmentId: '1', id: '12', nameFr: 'Carottes Biologique (sac 2lb)', nameEn: 'Organic Carrots (2lb)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003338390202.jpg' },
  { departmentId: '1', id: '13', nameFr: 'Carottes en feuille', nameEn: 'Carrots Bunch', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004094.jpg' },
  { departmentId: '1', id: '14', nameFr: 'Carottes Mini', nameEn: 'Mini Carrots', imageUrl: '/assets/CAROTTES mini.jpg' },
  { departmentId: '1', id: '15', nameFr: 'Celeri', nameEn: 'Celery', imageUrl: '/assets/CELERI.jpg' },
  { departmentId: '1', id: '16', nameFr: 'Celeri rave ', nameEn: 'Celery root', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004585.jpg' },
  { departmentId: '1', id: '17', nameFr: 'Champignons Blancs (8 oz)', nameEn: 'White Mushrooms (8 oz)', imageUrl: '/assets/CHAMPIGNONS BLANCS.jpg' },
  { departmentId: '1', id: '18', nameFr: 'Champignons Blancs tranches', nameEn: 'Sliced trip wash Mushrooms', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0003338367600.jpg' },
  { departmentId: '1', id: '19', nameFr: 'Champignons Café (8 oz)', nameEn: 'Café Mushrooms (8 oz)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0003338345046.jpg' },
  { departmentId: '1', id: '20', nameFr: 'Champignons Portobella', nameEn: 'Portobella Mushrooms', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0003338367606.jpg' },
  { departmentId: '1', id: '21', nameFr: 'Chou Vert', nameEn: 'Cabbage', imageUrl: '/assets/CHOU VERT.jpg' },
  { departmentId: '1', id: '22', nameFr: 'Chou Rouge', nameEn: 'Red Cabbage', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000003254.jpg' },
  { departmentId: '1', id: '23', nameFr: 'Chou Savoie', nameEn: 'Savoy Cabbage', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004955.jpg' },
  { departmentId: '1', id: '24', nameFr: 'Chou-Fleur', nameEn: 'Cauliflower', imageUrl: '/assets/CHOU-FLEUR.jpg' },
  { departmentId: '1', id: '25', nameFr: 'Chou frisé', nameEn: 'Kale', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004627.jpg' },
  { departmentId: '1', id: '26', nameFr: 'Chou de bruxelles', nameEn: 'Brussels Sprouts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004550.jpg' },
  { departmentId: '1', id: '27', nameFr: 'Crudité (1 plat)', nameEn: 'Varieties Vegetable (1 container)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0020856400000.jpg' },
  { departmentId: '1', id: '28', nameFr: 'Concombre Anglais', nameEn: 'Cucumber', imageUrl: '/assets/CONCOMBRE ANGLAIS.jpg' },
  { departmentId: '1', id: '29', nameFr: 'Concombre Green Ridge', nameEn: 'Field Cucumber', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004062.jpg' },
  { departmentId: '1', id: '30', nameFr: 'Concombres Libanais', nameEn: 'Mini cucumbers', imageUrl: '/assets/CONCOMBRE LIBANAIS.jpg' },
  { departmentId: '1', id: '31', nameFr: 'Courgettes (Zucchini)', nameEn: 'Zucchini', imageUrl: '/assets/COURGETTES.jpg' },
  { departmentId: '1', id: '32', nameFr: 'Courge Butternut', nameEn: 'Butternut Squash', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004759.jpg' },
  { departmentId: '1', id: '33', nameFr: 'Courge Spaghetti', nameEn: 'Spaghetti Squash', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004776.jpg' },
  { departmentId: '1', id: '34', nameFr: 'Echalotes francaises en paquet', nameEn: 'Dry Shallots', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0089071900101.jpg' },
  { departmentId: '1', id: '35', nameFr: 'Fèves Germées', nameEn: 'Bean sprouts', imageUrl: '/assets/FÈVES GERMÉES.jpg' },
  { departmentId: '1', id: '36', nameFr: 'Coriandre', nameEn: 'Cilantro Coriander', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004889.jpg' },
  { departmentId: '1', id: '37', nameFr: 'Haricots Vert (1 lb)', nameEn: 'Green Beans (1 lb)', imageUrl: '/assets/HARICOTS VERTS.jpg' },
  { departmentId: '1', id: '38', nameFr: 'Laitue Boston Duo', nameEn: 'Boston Duo ', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089578700019.jpg' },
  { departmentId: '1', id: '39', nameFr: 'Laitue Romaine', nameEn: 'Lettuce - Romaine', imageUrl: '/assets/LAITUE COEUR DE ROMAINE.jpg' },
  { departmentId: '1', id: '40', nameFr: 'Laitue en Feuille', nameEn: 'Lettuce – Green leaf', imageUrl: '/assets/LAITUE EN FEUILLE.jpg' },
  { departmentId: '1', id: '41', nameFr: 'Laitue Iceberg', nameEn: 'Lettuce – Iceberg', imageUrl: '/assets/LAITUE ICEBERG.jpg' },
  { departmentId: '1', id: '42', nameFr: 'Coeurs de laitue romaine', nameEn: 'Romaine Hearts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003338365162.jpg' },
  { departmentId: '1', id: '43', nameFr: 'Oignon Rouge en vrac', nameEn: 'Red onions', imageUrl: '/assets/OIGNON ROUGE.jpg' },
  { departmentId: '1', id: '44', nameFr: 'Oignons Jaunes (2 lb)', nameEn: 'Yellow Onions (2 lb)', imageUrl: '/assets/OIGNONS JAUNES.jpg' },
  { departmentId: '1', id: '45', nameFr: 'Oignon Blanc en vrac', nameEn: 'White Onions', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004663.jpg' },
  { departmentId: '1', id: '46', nameFr: 'Oignon Espagnol Gros', nameEn: 'Spanish Large Onions', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004093.jpg' },
  { departmentId: '1', id: '47', nameFr: 'Petits Oignons perlés rouge', nameEn: 'Red Pearl Onions', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005511700301.jpg' }, 
  { departmentId: '1', id: '48', nameFr: 'Petits Oignons perlés blanc', nameEn: 'White Pearl Onions', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005511700300.jpg' }, 
  { departmentId: '1', id: '49', nameFr: 'Petits Oignons perlés jaune', nameEn: 'Yellow Pearl Onions', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005511700302.jpg' },
  { departmentId: '1', id: '50', nameFr: 'Oignons Verts', nameEn: 'Green onions', imageUrl: '/assets/OIGNONS VERTS.jpg' },
  { departmentId: '1', id: '51', nameFr: 'Patate Douce', nameEn: 'Sweet potatoes', imageUrl: '/assets/PATATE DOUCE.jpg' },
  { departmentId: '1', id: '52', nameFr: 'Patate Blanche(sac 5lb)', nameEn: 'White potatoes(sac 5lb)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004083.jpg' },
  { departmentId: '1', id: '53', nameFr: 'Patate Rouge (sac 5lb)', nameEn: 'Red potatoes (sac 5lb)', imageUrl: 'https://cdn.shopify.com/s/files/1/0336/7501/products/red-potatoes_da1b9e35-c1e7-41b9-9b6f-5acc58ec565f_large.jpg?v=1550692318' },
  { departmentId: '1', id: '54', nameFr: 'Patate a chair jaune(sac 5lb)', nameEn: 'Yellow Flesh potatoes(sac 5lb)', imageUrl: 'https://cdn.shopify.com/s/files/1/0336/7501/products/yellow-potatoes_db0a5acb-1846-4067-96bf-6b10b0629969_large.jpg?v=1580324570' },
  { departmentId: '1', id: '55', nameFr: 'Patate Russett (sac 5lb)', nameEn: 'Russett Potato (sac 5lb)', imageUrl: '/assets/PATATES RUSSETT.jpg' },
  { departmentId: '1', id: '56', nameFr: 'Poivron Oranges', nameEn: 'Bell Pepper - Orange', imageUrl: '/assets/POIVRON ORANGE.jpg' },
  { departmentId: '1', id: '57', nameFr: 'Poivron Rouge', nameEn: 'Bell Pepper - Red', imageUrl: '/assets/POIVRON ROUGE.jpg' },
  { departmentId: '1', id: '58', nameFr: 'Poivron Vert', nameEn: 'Bell Pepper – Green', imageUrl: '/assets/POIVRON VERT.jpg' },
  { departmentId: '1', id: '59', nameFr: 'Persil frisé', nameEn: 'Regular Parsley', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004899.jpg' },
  { departmentId: '1', id: '60', nameFr: 'Persil italien', nameEn: 'Italian Parsley', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004901.jpg' },
  { departmentId: '1', id: '61', nameFr: 'Rutabagas (Navet)', nameEn: 'Rutabagas (Turnip)', imageUrl: '/assets/RUTABAGAS.jpg' },
  { departmentId: '1', id: '62', nameFr: 'Tomates Raisins', nameEn: 'Cherry Tomatoes', imageUrl: '/assets/TOMATES RAISINS.jpg' },
  { departmentId: '1', id: '63', nameFr: 'Tomates Cerises rouges', nameEn: 'Cherry Red Pint Tomatoes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003338365520.jpg' },
  { departmentId: '1', id: '64', nameFr: 'Tomates Italiennes Roma', nameEn: 'Plum Roma Tomatoes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004087.jpg' },
  { departmentId: '1', id: '65', nameFr: 'Tomates Biologiques Grappées', nameEn: 'Red Cluster Organic Tomatoes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000094664.jpg' },
  { departmentId: '1', id: '66', nameFr: 'Tomates Rouges De Serre', nameEn: 'Greenhouse Red Tomatoes', imageUrl: '/assets/TOMATES ROUGES DE SERRE.jpg' },
  { departmentId: '1', id: '67', nameFr: 'Tomates Rouges en Grappe Savoura', nameEn: 'Savoura Vine Red Tomatoes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004808.jpg' },
  { departmentId: '1', id: '68', nameFr: 'Salade Bébé épinard', nameEn: 'Baby Spinach', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088804800004.jpg' },
  { departmentId: '1', id: '69', nameFr: 'Salade Mélange printanier', nameEn: 'VegPro Spring Mix', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088804800004.jpg' },
  { departmentId: '1', id: '70', nameFr: 'Salade Roquette', nameEn: 'Arrugula Clamsell', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0088804800009.jpg' },
  { departmentId: '1', id: '71', nameFr: 'Tofu Ferme Biologique', nameEn: 'Regular Organic Tofu', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006528111111.jpg' },
  { departmentId: '1', id: '72', nameFr: 'Aubergine', nameEn: 'Eggplant', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004081.jpg' },
  { departmentId: '1', id: '73', nameFr: 'Tofu Biologique aux herbes', nameEn: 'Herb Organic Tofu', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006528111112.jpg' },
  { departmentId: '1', id: '723', nameFr: 'Bette a cadre', nameEn: 'Swiss chard', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004588.jpg' },
  { departmentId: '1', id: '724', nameFr: 'Fenouil', nameEn: 'Anise Fennel', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004515.jpg' },
  { departmentId: '1', id: '725', nameFr: 'Poireaux', nameEn: 'Leeks', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004629.jpg' },
  { departmentId: '1', id: '726', nameFr: 'Gingembre', nameEn: 'Ginger Root', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004612.jpg' },
  { departmentId: '1', id: '727', nameFr: 'Cavalo Guacamole authentique', nameEn: 'Cavalo Authentic Guacamole', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007074061635.jpg' },
 { departmentId: '1', id: '728', nameFr: 'Haricot vert( paquet 350gr)', nameEn: 'Green Beans( pack 350gr)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004066.jpg' },
 { departmentId: '1', id: '729', nameFr: 'Haricot vert francais ( paquet 350gr)', nameEn: 'French Green Beans( pack 350gr)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0018141800010.jpg' },
 { departmentId: '1', id: '730', nameFr: 'Herbes Salees', nameEn: 'Salted Herbes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0077231200019.jpg' },
 { departmentId: '1', id: '731', nameFr: 'Menthe fraiche en bouquet', nameEn: 'Fresh Mint', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006882010711.jpg' },
 { departmentId: '1', id: '732', nameFr: 'Basilic frais', nameEn: 'Fresh Basil', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006882010703.jpg' },
 { departmentId: '1', id: '733', nameFr: 'Romarin en bouquet', nameEn: 'Rosemary', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006882010715.jpg' },
 { departmentId: '1', id: '734', nameFr: 'Feuilles de laurier frais', nameEn: 'Fresh Bay Leaves', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006882011001.jpg' },
 { departmentId: '1', id: '735', nameFr: 'Thym fraiche', nameEn: 'Fresh Thyme', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006882010718.jpg' },
 { departmentId: '1', id: '735', nameFr: 'Sauge fraiche', nameEn: 'Fresh Sage', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006882010717.jpg' },
 { departmentId: '1', id: '736', nameFr: 'Nouilles frites', nameEn: 'Fried Noodles', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006906470712.jpg' },
 { departmentId: '1', id: '737', nameFr: 'Piment Jalapeno', nameEn: 'Jalapeno Pepper', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004693.jpg' },
 { departmentId: '1', id: '738', nameFr: 'Pois mange tout(paquet 200gr)', nameEn: 'Snow Peas(pack 200gr)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004092.jpg' },
 { departmentId: '1', id: '739', nameFr: 'ETu Kit a salade Cesar', nameEn: 'Cesar Original Kit Salade', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006854300002.jpg' },




  { departmentId: '10', id: '74', nameFr: 'Ananas Décortiqué', nameEn: 'Pineapple', imageUrl: '/assets/ananas.jpg' },
  { departmentId: '10', id: '75', nameFr: 'Ananas petit en morceaux', nameEn: 'Cut Small Pineapple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0020844000000.jpg' },
  { departmentId: '10', id: '76', nameFr: 'Ananas gros en morceaux', nameEn: 'Cut Large Pineapple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000008612.jpg' },
  { departmentId: '10', id: '77', nameFr: 'Avocat Hass', nameEn: 'Avocado', imageUrl: '/assets/avocat.jpg' },
  { departmentId: '10', id: '78', nameFr: 'Bananes', nameEn: 'Banana', imageUrl: '/assets/BANANES.jpg' },
  { departmentId: '10', id: '79', nameFr: 'Bleuets (Chopine)', nameEn: 'Blueberries (1 pack)', imageUrl: '/assets/BLEUETS.jpg' },
  { departmentId: '10', id: '80', nameFr: 'Orange sanguine', nameEn: 'Blood Oranges', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004381.jpg'  },
  { departmentId: '10', id: '81', nameFr: 'Cantaloup', nameEn: 'Cantaloupe', imageUrl: '/assets/CANTALOUP.jpg' },
  { departmentId: '10', id: '82', nameFr: 'Cantalop en morceaux', nameEn: 'Chunk Medium Cantaloupe', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0020840600000.jpg' },
  { departmentId: '10', id: '83', nameFr: 'Citron', nameEn: 'Lemon', imageUrl: '/assets/CITRON.jpg' },
  { departmentId: '10', id: '84', nameFr: 'Peche a chair blanche', nameEn: 'White Flesh Large Peaches', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004401.jpg' },
  { departmentId: '10', id: '85', nameFr: 'Fraises', nameEn: 'Strawberries', imageUrl: '/assets/FRAISES.jpg' },
  { departmentId: '10', id: '86', nameFr: 'Framboises', nameEn: 'Raspberries', imageUrl: '/assets/FRAMBOISES.jpg' },
  { departmentId: '10', id: '87', nameFr: 'Lime', nameEn: 'Lime', imageUrl: '/assets/LIME.jpg' },
  { departmentId: '10', id: '88', nameFr: 'Melon d’eau (Quartier)', nameEn: 'Watermelon (cut)', imageUrl: '/assets/MELON DEAU.jpg' },
  { departmentId: '10', id: '89', nameFr: 'Melon d’eau coupé en morceaux ', nameEn: 'Watermelon large container (cut)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0020844600000.jpg' },
  { departmentId: '10', id: '90', nameFr: 'Melon d’eau coupé en morceaux petit', nameEn: 'Watermelon small container (cut)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0020844300000.jpg' },
  { departmentId: '10', id: '91', nameFr: 'Mûres', nameEn: 'Blackberries', imageUrl: '/assets/MÛRES.jpg' },
  { departmentId: '10', id: '92', nameFr: 'Mangue Ataulfo', nameEn: 'Ataulfo Mango', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004961.jpg' },
  { departmentId: '10', id: '93', nameFr: 'Mangue Rouge Grosse', nameEn: 'Red Large Magoes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004959.jpg' },
  { departmentId: '10', id: '94', nameFr: 'Oranges Navel', nameEn: 'Navel Oranges', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004012.jpg' },
  { departmentId: '10', id: '95', nameFr: 'Pamplemousse Rouge', nameEn: 'Red Grapefruit', imageUrl: 'https://cdn.shopify.com/s/files/1/0336/7501/products/red-grapefruit_large.jpg?v=1468943554' },
  { departmentId: '10', id: '96', nameFr: 'Poire Bartlett', nameEn: 'Bartlett Pears', imageUrl: '/assets/POIRE BARTLETT.jpg' },
  { departmentId: '10', id: '97', nameFr: 'Poire Rocha', nameEn: 'Rocha Pears', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000003024.jpg' },
  { departmentId: '10', id: '98', nameFr: 'Grenade Grosse', nameEn: 'Pomegranate', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0082415004999.jpg' },
  { departmentId: '10', id: '99', nameFr: 'Pomme Gala', nameEn: 'Gala Apple', imageUrl: '/assets/POMME GALA.jpg' },
  { departmentId: '10', id: '100', nameFr: 'Pomme Honey Crisp', nameEn: 'HoneyCrisp Apple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000003283.jpg' },
  { departmentId: '10', id: '101', nameFr: 'Pomme Granny Smith', nameEn: 'Granny Smith Apple', imageUrl: '/assets/POMME GRANNY SMITH.jpg' },
  { departmentId: '10', id: '102', nameFr: 'Pomme Mcintosh', nameEn: 'Mcintosh Apple', imageUrl: '/assets/POMME MCINTOSH.jpg' },
  { departmentId: '10', id: '103', nameFr: 'Pomme Cortland', nameEn: 'Cortland Apple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004104.jpg' },
  { departmentId: '10', id: '104', nameFr: 'Pomme Delicieuse Jaune', nameEn: 'Golden Delicious Apple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004021.jpg' },
  { departmentId: '10', id: '105', nameFr: 'Pomme Delicieuse Rouge', nameEn: 'Red Delicious Apple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000094015.jpg' },
  { departmentId: '10', id: '106', nameFr: 'Pomme Pink Lady', nameEn: 'Cripps Pink Apple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004128.jpg' },
  { departmentId: '10', id: '107', nameFr: 'Pomme Spartan (sac)', nameEn: 'Spartan Apple(pack)', imageUrl: 'https://www.maisondelapomme.com/images/uploads/pommes_sac_10x4_lbs.jpg' },
  { departmentId: '10', id: '108', nameFr: 'Raisins Rouges Sans Pépins (1 lb)', nameEn: 'Seedless Red Grapes (1 lb)', imageUrl: '/assets/RAISINS ROUGES SANS PEPINS.jpg' },
  { departmentId: '10', id: '109', nameFr: 'Raisins Verts Sans Pépins (1 lb)', nameEn: 'Seedless Green Grapes (1 lb)', imageUrl: '/assets/RAISINS VERTS SANS PEPINS.jpg' },
  { departmentId: '10', id: '110', nameFr: 'Melon Miel', nameEn: 'Honeydew Melon', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000094329.jpg' },
  { departmentId: '10', id: '111', nameFr: 'Clémentine avec feuilles', nameEn: 'Clementine with leaves', imageUrl: 'https://image.migros.ch/original/61e74e4c445a4aed645b5f9d5acbbc1c0a94d2da/clementines-avec-feuille.jpg' },
  { departmentId: '10', id: '112', nameFr: 'Boite de Clémentines ', nameEn: 'Clementine Box', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/large/0003338300145.jpg' },
  { departmentId: '10', id: '113', nameFr: 'Kaki ', nameEn: 'Kaki', imageUrl: 'https://www.jaimefruitsetlegumes.ca/wp-content/uploads/2019/10/kaki-Tereza-Kr%CC%8Ci%CC%81z%CC%8Ckova%CC%81-from-Pixabay.jpg' },
  { departmentId: '10', id: '114', nameFr: 'Kiwi ', nameEn: 'Kiwi', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000004030.jpg' },
  { departmentId: '10', id: '115', nameFr: 'Kiwi doré', nameEn: 'Golden Kiwi', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0000000003279.jpg' },
  { departmentId: '10', id: '600', nameFr: 'Poire abate fetel', nameEn: 'Abate Fetel Pears', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000003012.jpg' },
  { departmentId: '10', id: '601', nameFr: 'Poire asiatique', nameEn: 'Asian Pears', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000000004407.jpg' },


  { departmentId: '2', id: '116', nameFr: 'Boursin échalotte-ciboulette', nameEn: 'Boursin shallot-chives', imageUrl: '/assets/BOURSIN.jpg' },
  { departmentId: '2', id: '117', nameFr: 'Brie', nameEn: 'Brie', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0022607500000.jpg' },
  { departmentId: '2', id: '118', nameFr: 'Caprice Des Dieux', nameEn: 'Caprice des Dieux cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0316171000013.jpg' },
  { departmentId: '2', id: '119', nameFr: 'Cheddar âgé', nameEn: 'Aged Cheddar cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0024517800000.jpg' },
  { departmentId: '2', id: '120', nameFr: 'Cheddar Doux', nameEn: 'Mild Cheddar cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005574253268.jpg' },
  { departmentId: '2', id: '121', nameFr: 'Fromage Feta', nameEn: 'Feta cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006882013016.jpg' },
  { departmentId: '2', id: '122', nameFr: 'Fromage en Grains', nameEn: 'Cheese curds', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006478600166.jpg' },
  { departmentId: '2', id: '123', nameFr: 'Fromage Jarlsberg', nameEn: 'Jarlsberg cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0025629300000.jpg' },
  { departmentId: '2', id: '124', nameFr: 'Fromage Oka Classique', nameEn: 'Oka Classic cheese', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006740062517.jpg' },
  { departmentId: '2', id: '125', nameFr: 'Parmesan Reggianno Rapé', nameEn: 'Parmesan Reggianno (gratted)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006491201754.jpg' },

  { departmentId: '3', id: '126', nameFr: 'Bacon Régulier', nameEn: 'Regular Bacon', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006530503201.jpg' },
  { departmentId: '3', id: '127', nameFr: 'Bavette De Boeuf Marinée', nameEn: 'Marinated Flap Meat Flank', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0022103400000.jpg' },
  { departmentId: '3', id: '128', nameFr: 'Boeuf Contre-Filet', nameEn: 'Striploin Grilling Steak', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021130500000.jpg' },
  { departmentId: '3', id: '129', nameFr: 'Boeuf Faux Filet', nameEn: 'Rib Eye Steak', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021121300000.jpg' },
  { departmentId: '3', id: '130', nameFr: 'Boeuf Filet Mignon', nameEn: 'Beef Tenderloin', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021138800000.jpg' },
  { departmentId: '3', id: '131', nameFr: 'Boeuf Hache Extra Maigre (1 lb)', nameEn: 'Extra Lean Ground Beef (1 lb)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0022111700000.jpg' },
  { departmentId: '3', id: '132', nameFr: 'Boeuf Hache Maigre (1 lb)', nameEn: 'Lean Ground Beef (1 lb)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021166500000.jpg' },
  { departmentId: '3', id: '133', nameFr: 'Boeuf Pour Fondue Chinoise', nameEn: 'Chinese Fondue Beef', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005574254991.jpg' },
  { departmentId: '3', id: '134', nameFr: 'Canard Cuisses Confites (2)', nameEn: 'Confit Duck Legs (2)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0062379800766.jpg' },
  { departmentId: '3', id: '135', nameFr: 'Cotes Levées Bbq', nameEn: 'Fresh Pork BBQ Back Ribs', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006670100908.jpg' },
  { departmentId: '3', id: '136', nameFr: 'Cubes de Boeuf à Mijoter (500g)', nameEn: 'Beef, Stewing Cubes (500g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021158700000.jpg' },
  { departmentId: '3', id: '138', nameFr: 'Porc Côtelettes', nameEn: 'Pork Chops', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0022331300000.jpg' },
  { departmentId: '3', id: '139', nameFr: 'Porc Frais Filet', nameEn: 'Fresh Pork Fillet', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021326200000.jpg' },
  { departmentId: '3', id: '140', nameFr: 'Porc Frais Roti De Longe', nameEn: 'Fresh Pork loin roast', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021328600000.jpg' },
  { departmentId: '3', id: '141', nameFr: 'Porc Haché Maigre', nameEn: 'Lean Ground Pork', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021343800000.jpg' },
  { departmentId: '3', id: '142', nameFr: 'Porc Roti Épaule', nameEn: 'Roasted Pork Shoulder', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021316800000.jpg' },
  { departmentId: '3', id: '143', nameFr: 'Poulet Cuisse Avec Dos', nameEn: 'Chicken Thigh With Back', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0024058300000.jpg' },
  { departmentId: '3', id: '144', nameFr: 'Poulet Entier Frais', nameEn: 'Fresh Whole Chicken', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0023090400000.jpg' },
  { departmentId: '3', id: '145', nameFr: 'Poulet Poitrine Désossée', nameEn: 'Boneless Chicken Breast', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0023058100000.jpg' },
  { departmentId: '3', id: '146', nameFr: 'Roti Bas Palette Désossé', nameEn: 'Boneless bottom blade roast', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021115100000.jpg' },
  { departmentId: '3', id: '147', nameFr: 'Roti Français', nameEn: 'French Roast beef', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0027129200000.jpg' },
  { departmentId: '3', id: '148', nameFr: 'Saucisses Déjeuner (Porc/Bœuf)', nameEn: 'Breakfast Sausage (Beef/Pork)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006530542205.jpg' },
  { departmentId: '3', id: '149', nameFr: 'Saucisses Hot Dog', nameEn: 'Hot Dog Sausages', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0006530502201.jpg' },
  { departmentId: '3', id: '150', nameFr: 'Saucisses Italiennes Douces', nameEn: 'Sweet Italian Sausages', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005745962502.jpg' },
  { departmentId: '3', id: '151', nameFr: 'Veau Haché Maigre (1 lb)', nameEn: 'Lean Ground Veal (1 lb)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021272400000.jpg' },
  { departmentId: '3', id: '152', nameFr: 'Viandes Fumée Schwartz (4 Un.)', nameEn: 'Schwartz Smoked Meat (4 Un.)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0062753396123.jpg' },
 
  { departmentId: '4', id: '153', nameFr: 'Aiglefin Frais Filet', nameEn: 'Fresh Haddock Fillet', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021808600000.jpg' },
  { departmentId: '4', id: '154', nameFr: 'Crevettes Crues Rose', nameEn: 'Raw Pink Shrimps', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005937180330.jpg' },
  { departmentId: '4', id: '155', nameFr: 'Crevettes Cuites (61-70 Ou 31-40)', nameEn: 'Cooked Shrimps (61-70 or 31-40)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005937179014.jpg' },
  { departmentId: '4', id: '156', nameFr: 'Crevettes Nordiques (227g)', nameEn: 'Nordic Shrimp (227g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0062850410605.jpg' },
  { departmentId: '4', id: '157', nameFr: 'Goberge (227g)', nameEn: 'Pollock (227g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0005574238235.jpg' },
  { departmentId: '4', id: '159', nameFr: 'Morue Pacifique Filet', nameEn: 'Pacific Cod Fillet', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/0021809100000.jpg' },
  { departmentId: '4', id: '160', nameFr: 'Poisson en pâté', nameEn: 'Fish pâté', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '4', id: '161', nameFr: 'Saumon Filet (Env. 230g)', nameEn: 'Salmon Filet (approx. 230g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '4', id: '162', nameFr: 'Saumon Fumé (113g)', nameEn: 'Smoked Salmon (113g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '4', id: '163', nameFr: 'Saumon Tournedos Avec Crevettes', nameEn: 'Salmon Tournedos with Shrimps', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '4', id: '164', nameFr: 'Filet de Truite Fraiche', nameEn: 'Fresh Trout Fillet', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
 
  { departmentId: '5', id: '165', nameFr: 'Cretons', nameEn: 'Cretons', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '166', nameFr: 'Bologne (150g)', nameEn: 'Bologna (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '167', nameFr: 'Jambon Blanc (150g)', nameEn: 'Ham (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '169', nameFr: 'Mortadelle (150g)', nameEn: 'Mortadella (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '170', nameFr: 'Pastrami Boeuf Fumé (150g)', nameEn: 'Smoked Beef Pastrami (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '171', nameFr: 'Pepperoni Tranché (150g)', nameEn: 'Sliced ​​Pepperoni (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '172', nameFr: 'Prosciutto (150g)', nameEn: 'Prosciutto (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '173', nameFr: 'Roti Dinde Au Four (150g)', nameEn: 'Baked Turkey Roast (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '5', id: '174', nameFr: 'Simili Poulet (150g)', nameEn: 'Imitation Chicken (150g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
 
  { departmentId: '6', id: '175', nameFr: 'POM pain blanc', nameEn: 'POM White Bread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777120.jpg' },
  { departmentId: '6', id: '176', nameFr: 'POM Pain de ble', nameEn: 'POM wheat Bread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777121.jpg' },
  { departmentId: '6', id: '177', nameFr: 'Bagel sesame ChefFrankie', nameEn: 'ChefFrankie Sesame Bagel', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067416500001.jpg' },
  { departmentId: '6', id: '178', nameFr: 'Baguette Ciabatta', nameEn: 'Ciabatta Baguette', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748352663.jpg' },
  { departmentId: '6', id: '179', nameFr: 'Baguette Europeenne', nameEn: 'European Baguette', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748300132.jpg' },
  { departmentId: '6', id: '180', nameFr: 'Bouchées de brownies (300g)', nameEn: 'Brownie bites (300g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006882013532.jpg' },
  { departmentId: '6', id: '181', nameFr: 'Croissant (6)', nameEn: 'Croissant (6)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0021165000000.jpg' },
  { departmentId: '6', id: '182', nameFr: 'Chocolatine (2)', nameEn: 'Chocolatine (2)', imageUrl: 'https://az826390.vo.msecnd.net/cdn/media/home/produits_a_decouvrir/boulangerie/viennoiseries/produits-a-decouvrir-boulangerie-viennoiseries-origines-choclatine-700x700.ashx?la=en&w=700&hash=8BA661ABA398D971EBB2C610A4131C441100E667' },
  { departmentId: '6', id: '183', nameFr: 'Mini Chocolatine Petite Bretonne', nameEn: 'Micro Chocolatine Petite Bretonne', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005559897760.jpg' },
  { departmentId: '6', id: '184', nameFr: 'Chocolatine Petite Bretonne', nameEn: 'Chocolat Buns Petite Bretonne', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005559898127.jpg' },
  { departmentId: '6', id: '185', nameFr: 'Pain epeautre Bio', nameEn: 'Spelled Loaf Bread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062784319990.jpg' },
  { departmentId: '6', id: '186', nameFr: 'Miche Paysan Pain Dore', nameEn: 'Sliced Loaf bread Pain Dore', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748352744.jpg' },
  { departmentId: '6', id: '187', nameFr: 'Miche Blanc Premiere Moisson', nameEn: 'Premiere Moisson Country Style Loaf', imageUrl: 'https://product-images.metro.ca/images/hdf/h43/9252588290078.jpg' },
  { departmentId: '6', id: '188', nameFr: 'Miche Ble Premiere Moisson', nameEn: 'Whole wheat loaf PM', imageUrl: 'https://product-images.metro.ca/images/h74/h4b/9252589928478.jpg' },
  { departmentId: '6', id: '189', nameFr: 'Mini Croissant', nameEn: 'Micro Croissant Pack', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005559897735.jpg' },
  { departmentId: '6', id: '190', nameFr: 'Beignes nature (6)', nameEn: 'Plain Donuts (6)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0029823700000.jpg' },
  { departmentId: '6', id: '191', nameFr: 'Demi Ciabatta', nameEn: 'Ciabatta Half Baguette', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748352664.jpg' },
  { departmentId: '6', id: '192', nameFr: 'Pain Naan rond Original', nameEn: 'Original Round Naan', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574256053.jpg' },
  { departmentId: '6', id: '193', nameFr: 'Pain Naan aux oignons caramelises', nameEn: 'Caramelized Onion Naan', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062368210314.jpg' },
  { departmentId: '6', id: '194', nameFr: 'Painn Naan Original', nameEn: 'Original Naan', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/large/0062368210318.jpg' },
  { departmentId: '6', id: '195', nameFr: 'Kaiser au sesame (4)', nameEn: 'Sesame Kaiser rolls (4)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0029771300000.jpg' },
  { departmentId: '6', id: '196', nameFr: 'Kaiser Nature (4)', nameEn: 'Plain Kaiser rolls (4)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0022776500000.jpg' },
  { departmentId: '6', id: '197', nameFr: 'Saint Michel Donuts aux pepites de chocolat', nameEn: 'Saint Michel Chocolat Chip Donuts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0317853041741.jpg' },
  { departmentId: '6', id: '198', nameFr: 'Saint Michel Donuts Marbre', nameEn: 'Saint Michel Marble Donuts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0317853041270.jpg' },
  { departmentId: '6', id: '199', nameFr: 'Vachon Passion Flakie', nameEn: 'Vachon Passion Flakie', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006073705211.jpg' },
  { departmentId: '6', id: '200', nameFr: 'Vachon Jos Louis', nameEn: 'Vachon Jos Louis', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006073705600.jpg' },
  { departmentId: '6', id: '201', nameFr: 'Vachon May West', nameEn: 'Vachon May West', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006073705404.jpg' },
  { departmentId: '6', id: '202', nameFr: 'Vachon Ah Caramel', nameEn: 'Vachon Ah Caramel', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006073705301.jpg' },
  { departmentId: '6', id: '203', nameFr: 'Vachon Mille feuilles', nameEn: 'Vachon Mille feuilles', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006073705110.jpg' },
  { departmentId: '6', id: '204', nameFr: 'Pain raisin Sun Maid', nameEn: 'Rasisin Cinnamon Swirl Bread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006872100400.jpg' },
  { departmentId: '6', id: '205', nameFr: 'Pain blanc Villaggio Classico', nameEn: 'White Bread Villaggio Classico', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777124.jpg' },
  { departmentId: '6', id: '206', nameFr: 'Pain ble Villaggio', nameEn: 'Wheat Bread Villaggio', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777127.jpg' },
  { departmentId: '6', id: '207', nameFr: 'Pain Campagnards Bon Matin', nameEn: 'Bon Matin Homestyle Country Grain', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107776001.jpg' },
  { departmentId: '6', id: '208', nameFr: '14 cereales Bon Matin', nameEn: 'Bon Matin 14 Grain Bread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107794030.jpg' },
 { departmentId: '6', id: '700', nameFr:'Miche rustique Pain Dore', nameEn: 'Country Loaf Bread Pain Dore',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748300139.jpg' },
 { departmentId: '6', id: '701', nameFr:'Muffin bananes et chocolat(4)', nameEn: 'Banana Chocolat Muffin (4)',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0087668100009.jpg' },
 { departmentId: '6', id: '702', nameFr:'Muffins aux bleuets (4)', nameEn: 'Blueberry Muffin (4)',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0087668100281.jpg' }, 
 { departmentId: '6', id: '703', nameFr:'Muffins aux carottes (4)', nameEn: 'Carrots Muffin (4)',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0087668100282.jpg' }, 
 { departmentId: '6', id: '704', nameFr:'Pain aux noix et raisins', nameEn: 'Nut & Raisin Bread',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005748300141.jpg' }, 
 { departmentId: '6', id: '705', nameFr:'8 Pains au lait Grabriel', nameEn: '8 Milk Brioche Rolls',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0325654000237.jpg' },
 { departmentId: '6', id: '706', nameFr:'Pain Belge', nameEn: 'Belgian Bread',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0027765600000.jpg' },
 { departmentId: '6', id: '707', nameFr:'Pain Dore au sesame et tournesol', nameEn: 'Pain Dore Sesame Sunflower Bread',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0083160400990.jpg' },
 { departmentId: '6', id: '708', nameFr:'Pain Dore Artisan Multigrain tranche', nameEn: 'Sliced Artisan Bread Multigrain',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0083160400952.jpg' },
 { departmentId: '6', id: '709', nameFr:'Panini Multigrain Saveurs du Four', nameEn:'Panini Multigrain Saveurs du Four',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0077097412130.jpg' },
 { departmentId: '6', id: '710', nameFr:'Paquet Petit pain ciabatta aux olives', nameEn:'Mini Olives ciabatta Pack',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0028556800000.jpg' },
 { departmentId: '6', id: '711', nameFr:'Sucre a la creme erable', nameEn:'Maple Cream Fudge',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005802310235.jpg' }, 
 { departmentId: '6', id: '712', nameFr:'Tortillas de ble Mejicano', nameEn:'Whole Wheat Tortillas Mejicano',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062611410650.jpg' }, 
 { departmentId: '6', id: '713', nameFr:'Tortillas de ble entier et huile olive Mejicano', nameEn:'Whole Wheat Olive oil Tortillas Mejicano',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062611450600.jpg' },
 { departmentId: '6', id: '714', nameFr:'Tortillas regulieres Mejicano', nameEn:'Plain Tortillas Mejicano',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062611410645.jpg' },
 { departmentId: '6', id: '715', nameFr:'Pain aux grains rustiques Bon Matin', nameEn:'Ancient Grains Bon Matin',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107754070.jpg' },
 { departmentId: '6', id: '716', nameFr:'Pain multigrain Bon Matin', nameEn:'Multigrain Grains Bon Matin',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107794022.jpg' },
 { departmentId: '6', id: '717', nameFr:'Pain sans gluten ciabatta', nameEn:'Gluten free ciabatta',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081075701073.jpg' }, 
 { departmentId: '6', id: '718', nameFr:'Pain blanc artisan sans gluten', nameEn:'Gluten free white bread',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081075701125.jpg' }, 
 { departmentId: '6', id: '719', nameFr:'Pain Belge 12 cereales Bon Matin', nameEn:'12 Grains Bread Bon Matin',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107776006.jpg' },
 { departmentId: '6', id: '720', nameFr:'Pain Siegle Bon Matin', nameEn:'Rye Bread Bon Matin',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107776004.jpg' },
 { departmentId: '6', id: '721', nameFr:'Pain hamburger POM', nameEn:'Hamburger Bread POM',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777116.jpg' },
 { departmentId: '6', id: '722', nameFr:'Pain hot dog POM', nameEn:'Hot dog Bread POM',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006107777115.jpg' },
























 
  { departmentId: '7', id: '209', nameFr: 'Lasagne a la viande', nameEn: 'Meat Lasagna', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '210', nameFr: 'Pâté au poulet', nameEn: 'Chicken pot pie', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '211', nameFr: 'Pâté au saumon', nameEn: 'Salmon pâté', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '212', nameFr: 'Pâté chinois (350g)', nameEn: 'Shepherd\'s Pie (350g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '213', nameFr: 'Poulet Parmigiana', nameEn: 'Parmigiana chicken', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '214', nameFr: 'Quiche Lorraine (575g)', nameEn: 'Quiche Lorraine (575g)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '215', nameFr: 'Salade de chou crémeuse', nameEn: 'Creamy coleslaw', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '216', nameFr: 'Salade de chou traditionnelle', nameEn: 'Traditional coleslaw', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '217', nameFr: 'Sandwich farci jambon', nameEn: 'Ham sandwich', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '218', nameFr: 'Sandwich farci œufs', nameEn: 'Egg sandwich', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '219', nameFr: 'Sandwich farci poulet', nameEn: 'Chicken sandwich', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '220', nameFr: 'Sauce a spaghetti', nameEn: 'Spaghetti sauce', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '221', nameFr: 'Soupe aux légumes', nameEn: 'Vegetable soup', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
  { departmentId: '7', id: '222', nameFr: 'Tourtière boeuf et porc', nameEn: 'Beef and pork pie', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/small/.jpg' },
 
  { departmentId: '8', id: '223', nameFr: 'Abricots séchés', nameEn: 'Abricots', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574236920.jpg' },
  { departmentId: '8', id: '224', nameFr: 'Ananas tranchés dans jus', nameEn: 'Slices in juice Pineapple', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006525000286.jpg' },
  { departmentId: '8', id: '225', nameFr: 'Arachides Grillées', nameEn: 'Dry roasted peanuts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005871698959.jpg' },
  { departmentId: '8', id: '226', nameFr: 'Barre de céréales entiere au miel', nameEn: 'Chewy Honey Almond Flax Bars', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001862710481.jpg' },
  { departmentId: '8', id: '227', nameFr: 'Barre de céréales entieres au moka foncé', nameEn: 'Chewy Dark Moka Almond Bars', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001862710483.jpg' },
  { departmentId: '8', id: '228', nameFr: 'Barre granola avoine et miel ', nameEn: 'Crunchy Oats N Honey Granola Bars', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563307470.jpg' },
  { departmentId: '8', id: '229', nameFr: 'Barre granola au beurre arachide', nameEn: 'Crunchy Peanut Butter Granola Bars', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563307471.jpg' },
  { departmentId: '8', id: '230', nameFr: 'Barre granola sucre sale noix amande', nameEn: 'Sweet and Salted Chewy Nut Almond Granola Bars', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563315739.jpg' },
  { departmentId: '8', id: '231', nameFr: 'Beurre cremeux aux amandes NTY', nameEn: 'NTY Smooth Almond Butter', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006889240080.jpg' },
  { departmentId: '8', id: '232', nameFr: 'Beurre arachide crémeux Bio Compliment', nameEn: 'Organic Peanut Butter Compliment', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574236715.jpg' },
  { departmentId: '8', id: '233', nameFr: 'Beurre arachide crémeux Compliment', nameEn: 'Compliment Smooth Peanut Butter', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574234827.jpg' },
  { departmentId: '8', id: '234', nameFr: 'Beurre arachide crémeux Kraft', nameEn: 'Kraft Smooth Peanut Butter', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810008421.jpg' },
  { departmentId: '8', id: '235', nameFr: 'Beurre arachide non sucre Kraft', nameEn: 'Kraft Unsweetened Peanut Butter', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/large/0006810008385.jpg' },
  { departmentId: '8', id: '236', nameFr: 'Beurre arachide croquant Kraft', nameEn: 'Kraft Crunchy Peanut Butter', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810008422.jpg' },
  { departmentId: '8', id: '237', nameFr: 'Biscuits Maria', nameEn: 'Maria Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0079513000907.jpg' },
  { departmentId: '8', id: '238', nameFr: 'Biscuits digestifs Petits Plaisirs', nameEn: 'Digestive Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005565313990.jpg' },
  { departmentId: '8', id: '239', nameFr: 'Biscuits thé social Petits Plaisirs', nameEn: 'Simple Pleasures Social tea Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005565313970.jpg' },
  { departmentId: '8', id: '240', nameFr: 'Biscuits avoine Petits Plaisirs', nameEn: 'Simple Pleasure Oatmeal Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005565314080.jpg' },
  { departmentId: '8', id: '241', nameFr: 'Biscuits avoine originaux Dads', nameEn: 'Dads Original Oatmeal Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005683300011.jpg' },
  { departmentId: '8', id: '242', nameFr: 'Biscuits Flocons avoine Celebration', nameEn: 'Oatmeal Chocolat Celebration', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200669.jpg' },
  { departmentId: '8', id: '243', nameFr: 'Biscuits au beurre chocolat au lait Celebration', nameEn: 'Celebration Milk Chocolat Top Butter Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200642.jpg' },
  { departmentId: '8', id: '244', nameFr: 'Biscuits au caramel & chocolat au lait Celebration', nameEn: 'Caramel Milk Chocolat Butter Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200341.jpg' },
  { departmentId: '8', id: '245', nameFr: 'Biscuits au chocolat noir 70% Celebration', nameEn: 'Celebration Dark Chocolat 70% Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200673.jpg' },
  { departmentId: '8', id: '246', nameFr: 'Biscuits au lait de coco David', nameEn: 'Milk Coco Cookies David', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005635864008.jpg' },
  { departmentId: '8', id: '247', nameFr: 'Biscuits Brownie Pattes Ours', nameEn: 'Bear Paws Brownies Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800691.jpg' },
  { departmentId: '8', id: '248', nameFr: 'Biscuits a la melasse Pattes Ours', nameEn: 'Bear Paws Molasses Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800693.jpg' },
  { departmentId: '8', id: '249', nameFr: 'Biscuits Belge au chocolat au lait Leclerc', nameEn: 'Leclerc Chocolat Belge Cookie ', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200607.jpg' },
  { departmentId: '8', id: '250', nameFr: 'Biscuits Choco duo chocolat Leclerc', nameEn: 'Leclerc Chocolate Duo Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200614.jpg' },
  { departmentId: '8', id: '251', nameFr: 'Biscuits en forme Feuille Erable', nameEn: 'Maple Leaf Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005635862019.jpg' },
  { departmentId: '8', id: '252', nameFr: 'Biscuits Maxi Fruits aux dattes', nameEn: 'Maxi Fruits Dates Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800436.jpg' },
  { departmentId: '8', id: '253', nameFr: 'Biscuits croquant au son Peek Freans', nameEn: 'Lifestyle Bran Crunch Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006598720435.jpg' },
  { departmentId: '8', id: '254', nameFr: 'Biscuits Graham Gaufrés', nameEn: 'Honey Maid Graham Wafers', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672101041.jpg' },
  { departmentId: '8', id: '255', nameFr: 'Biscuits Oreo Original', nameEn: 'Original Oreo Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100569.jpg' },
  { departmentId: '8', id: '256', nameFr: 'Biscuits Fudgee-O original', nameEn: 'Fudgee-O Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006672100586.jpg' },
  { departmentId: '8', id: '257', nameFr: 'Biscuits The Social Christie', nameEn: 'Christie Social Tea Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672101215.jpg' },
  { departmentId: '8', id: '258', nameFr: 'Biscuits Normandie Graham', nameEn: 'Graham Normandie Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334820645.jpg' },
  { departmentId: '8', id: '259', nameFr: 'Biscuits Normandie Batonnets', nameEn: 'Normandie Sticks Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334820642.jpg' },
  { departmentId: '8', id: '260', nameFr: 'Biscuits ChipsAhoy Original', nameEn: 'Original ChipsAhoy Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100667.jpg' },
  { departmentId: '8', id: '261', nameFr: 'Biscuits Whippet Original', nameEn: 'Original Whippet Cookie', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800303.jpg' },
  { departmentId: '8', id: '262', nameFr: 'Biscuits Whippet Noir Original', nameEn: 'Original Dark Whippet Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334801070.jpg' },
  { departmentId: '8', id: '263', nameFr: 'Biscuits Whippet aux fraises', nameEn: 'Strawberry Whippet Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800365.jpg' },
  { departmentId: '8', id: '264', nameFr: 'Biscuits Whippet aux framboises', nameEn: 'Rasberry Whippet Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006334800366.jpg' },
  { departmentId: '8', id: '265', nameFr: 'Biscuits Vital aux amandes', nameEn: 'Vital Almond Oat Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200391.jpg' },
  { departmentId: '8', id: '266', nameFr: 'Biscuits Vital aux canneberges', nameEn: 'Vital Cranberry Oat Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200394.jpg' },
  { departmentId: '8', id: '267', nameFr: 'Bonbons durs Werthers Original sans sucre ajouté', nameEn: 'No Sugar Added Hard Candies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007279933868.jpg' },
  { departmentId: '8', id: '268', nameFr: 'Bonbons durs Werthers Original', nameEn: 'Werthers Original Hard Candies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007279932978.jpg' },
  { departmentId: '8', id: '269', nameFr: 'Bouillon de boeuf Campbells 900ml', nameEn: 'Beef Broth Campbells 900ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321114358.jpg' },
  { departmentId: '8', id: '270', nameFr: 'Bouillon de boeuf 30% moins de sel Campbells', nameEn: '30% Low Sodium Beef Broth', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321114816.jpg' },
  { departmentId: '8', id: '271', nameFr: 'Bouillon de boeuf sans sel Campbells', nameEn: 'Beef No Salt Added Broth', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321119895.jpg' },
  { departmentId: '8', id: '272', nameFr: 'Bouillon de légume Campbells 900ml', nameEn: 'Vegetable Broth Campbells', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321118246.jpg' },
  { departmentId: '8', id: '273', nameFr: 'Bouillon de poulet Compliment 900ml', nameEn: 'Compliment Chicken Broth 900ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574251259.jpg' },
  { departmentId: '8', id: '274', nameFr: 'Bouillon de poulet Imagine', nameEn: 'Imagine Organic Chicken Broth', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008425324249.jpg' },
  { departmentId: '8', id: '275', nameFr: 'Bouillon de légume Imagine', nameEn: 'Imagine Organic Vegetable Broth', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008425324248.jpg' },
  { departmentId: '8', id: '276', nameFr: 'Bouillon Miso Imagine', nameEn: 'Miso Organic Broth', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008425377354.jpg' },
  { departmentId: '8', id: '277', nameFr: 'Bouillon de poulet fermier Bio Campbells', nameEn: 'Organic Free Range Chicken Broth Campbells', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321116965.jpg' },
  { departmentId: '8', id: '278', nameFr: 'Bouillon de poulet Campbells 900ml', nameEn: 'Campbells Chicken Broth 900ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321112114.jpg' },
  { departmentId: '8', id: '279', nameFr: 'Bouillon de poulet sans sel Campbells 900ml', nameEn: 'Campbells Chicken No Salt Added Broth 900ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006321119455.jpg' },
  { departmentId: '8', id: '280', nameFr: 'Bouillon concentré de poulet en cube', nameEn: 'Chicken Bouillon Cube', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005522000001.jpg' },
  { departmentId: '8', id: '281', nameFr: 'Bouillon Cube Selects Boeuf Knorr', nameEn: ' Bouillon Cube Selects Beef Knorr', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005522000008.jpg' },
  { departmentId: '8', id: '282', nameFr: 'Biscuits crepe dentelle Gavottes ', nameEn: ' Crispy Crepes cookies Gavottes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081647502012.jpg' },
  { departmentId: '8', id: '283', nameFr: 'Bettraves entieres marinées Kuhne ', nameEn: ' Whole Pickled Beet Kuhne', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003904543269.jpg' },
  { departmentId: '8', id: '284', nameFr: 'Bouillon concentré de boef Bovril ', nameEn: ' Beef Bouillon concentrate Bovril', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006140000027.jpg' }, 
  { departmentId: '8', id: '285', nameFr: 'Bouillon concentré de poulet Bovril ', nameEn: ' Chicken Bouillon concentrate Bovril', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006140000057.jpg' },
  { departmentId: '8', id: '464', nameFr: 'Canton Bouillon a fondu', nameEn: 'Canton Broth for Hot pot', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005526622290.jpg' },
  { departmentId: '8', id: '465', nameFr: 'Bouillon deshydrate de poulet', nameEn: 'Loneys Dehydrated Broth', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006963600500.jpg' },
  { departmentId: '8', id: '286', nameFr: 'Bretzels classiques minces', nameEn: 'Classic Thins Pretzels', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041003262.jpg' },
  { departmentId: '8', id: '287', nameFr: 'Buger Biologiques sans gluten', nameEn: 'Organic Burger Mix', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900032.jpg' },
  { departmentId: '8', id: '288', nameFr: 'Cacao Frys', nameEn: 'Frys Premium Cacao', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062823388310.jpg' },
  { departmentId: '8', id: '289', nameFr: 'Café Nescafe Gold espresso', nameEn: 'Gold Espresso Instant Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005500037776.jpg' },
  { departmentId: '8', id: '290', nameFr: 'Café moulu espresso Nabob', nameEn: 'Ground Espresso Coffee Nabob', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006020000176.jpg' },
  { departmentId: '8', id: '291', nameFr: 'Café moulu summit Nabob', nameEn: 'Summit 100% Colombian Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006020000182.jpg' },
  { departmentId: '8', id: '292', nameFr: 'Café Nespresso Espresso Starbuck', nameEn: 'Starbuck Nespresso Espresso Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0761303817572.jpg' },
  { departmentId: '8', id: '293', nameFr: 'Café expresso moulu TimHortons', nameEn: 'Tim Hortons Expresso Ground Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006320911337.jpg' },
  { departmentId: '8', id: '294', nameFr: 'Café moulu 100% colombbien TimHortons', nameEn: 'TimHortons 100% Columbian Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006320909592.jpg' },
  { departmentId: '8', id: '295', nameFr: 'Café Melange original TimHortons', nameEn: 'Tim Hortons Original Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006320908231.jpg' },
  { departmentId: '8', id: '296', nameFr: 'Café decafiné TimHortons 640gr', nameEn: 'Tim Hortons Decafe Coffee 640gr', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006320911763.jpg' },
  { departmentId: '8', id: '297', nameFr: 'Café intenso Agga', nameEn: 'Agga Intenso Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005851899163.jpg' },
  { departmentId: '8', id: '298', nameFr: 'Café K-cup colombien VanHoutte', nameEn: 'K-cup Columbian VanHoutte', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006215163790.jpg' },
  { departmentId: '8', id: '299', nameFr: 'Café moka creme moulu VanHoutte', nameEn: 'Mellow moka light coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006215141397.jpg' },
  { departmentId: '8', id: '300', nameFr: 'Café espresso decisio Lavazza', nameEn: 'Lavazza Deciso Capsules Espresso', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0004195300091.jpg' },
  { departmentId: '8', id: '301', nameFr: 'Café espresso armonico capsules Lavazza', nameEn: 'Lavazza Armonico Capsules Espresso', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0004195300089.jpg' },
  { departmentId: '8', id: '302', nameFr: 'Café moulu Rossa Lavazza', nameEn: 'Lavazza Rossa Brick Coffee ', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004195303615.jpg' },
  { departmentId: '8', id: '303', nameFr: 'Café moulu Crema gusto Lavazza', nameEn: 'Lavazza Creama Gusto Brick Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004195303835.jpg' },
  { departmentId: '8', id: '545', nameFr: 'Café Tasters Choice Instantané classique', nameEn: 'Tasters Choice Classic Instant Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005500000255.jpg' },
  { departmentId: '8', id: '546', nameFr: 'Nescafe Instantané riche', nameEn: 'Nescafe Rich Instant Coffee', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005500000881.jpg' },
  { departmentId: '8', id: '304', nameFr: 'Carottes entieres Compliment 398ml', nameEn: 'Compliment Whole Baby Carrots', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574236491.jpg' },
  { departmentId: '8', id: '305', nameFr: 'Céréale Shreddies Original', nameEn: 'Shreddies Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0062815401194.jpg' },
  { departmentId: '8', id: '306', nameFr: 'Céréale Froot Loops', nameEn: 'Froot Loops Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006410059572.jpg' },
  { departmentId: '8', id: '307', nameFr: 'Céréale Mini-Wheat saveur cassonade', nameEn: 'Mini Wheats Brown Sugar', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410059013.jpg' },
  { departmentId: '8', id: '308', nameFr: 'Céréale Mini-Wheat original', nameEn: 'Original Mini Wheats', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410059000.jpg' },
  { departmentId: '8', id: '309', nameFr: 'Céréale Cheerios', nameEn: 'Cheerios Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006563313281.jpg' },
  { departmentId: '8', id: '310', nameFr: 'Céréale Cheerios Miel et Noix', nameEn: 'Honey Nut Cheerios', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563313294.jpg' },
  { departmentId: '8', id: '311', nameFr: 'Céréale Cheerios Pommes et Cannelle', nameEn: 'Apple Cinnamon Cheerios Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006563313282.jpg' },
  { departmentId: '8', id: '312', nameFr: 'Céréales aux biscuits graham', nameEn: 'Golden Grahams Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563313295.jpg' },
  { departmentId: '8', id: '313', nameFr: 'Céréale aux flocons Vector', nameEn: 'Vector Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410003632.jpg' },
  { departmentId: '8', id: '314', nameFr: 'Céréale bouchées avoine au miel avec amandes', nameEn: 'Honey Bunches Oats Almond Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062815404876.jpg' },
  { departmentId: '8', id: '315', nameFr: 'Céréale Fibre 1 Croquant Original', nameEn: 'Fibre 1 Crunchy Original Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563307563.jpg' },
  { departmentId: '8', id: '316', nameFr: 'Céréale Rice Krispies', nameEn: 'Rice Krispies Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410059557.jpg' },
  { departmentId: '8', id: '317', nameFr: 'Céréale en flocons All Bran', nameEn: 'All Bran Flakes Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410012227.jpg' },
  { departmentId: '8', id: '318', nameFr: 'Céréale au psyllium All Bran', nameEn: 'All Bran Buds Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410000070.jpg' },
  { departmentId: '8', id: '319', nameFr: 'Céréale All Bran Original', nameEn: 'Original All Bran Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410000146.jpg' },
  { departmentId: '8', id: '320', nameFr: 'Céréale en paquet varié', nameEn: 'Fun Pack Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410023047.jpg' },
  { departmentId: '8', id: '321', nameFr: 'Céréale Special K baies Rouge', nameEn: 'Special K Red Berries Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006410077906.jpg' },
  { departmentId: '8', id: '322', nameFr: 'Céréale Special K Vanille et Amande', nameEn: 'Special K Vanilla Almond Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410058991.jpg' },
  { departmentId: '8', id: '323', nameFr: 'Céréale Special K original', nameEn: 'Original Special K Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006410010621.jpg' },
  { departmentId: '8', id: '324', nameFr: 'Céréale croquant Kashi', nameEn: 'Kashi Go Lean Crunch Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001862759763.jpg' },
  { departmentId: '8', id: '325', nameFr: 'Céréale chocolat croquant Kashi', nameEn: 'Kashi Go Lean Chocolat Crunch Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001862710263.jpg' },
  { departmentId: '8', id: '326', nameFr: 'Céréale au miel Kashi', nameEn: 'Kashi Honey Almond Flax Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001862759761.jpg' },
  { departmentId: '8', id: '327', nameFr: 'Canapé Melba au sésame', nameEn: 'Canape Sesame Melba', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005695142054.jpg' },
  { departmentId: '8', id: '328', nameFr: 'Canapé Melba nature', nameEn: 'Canape Plain Melba Toast', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005695142051.jpg' },
  { departmentId: '8', id: '329', nameFr: 'Céréale aphrodisiaque', nameEn: 'Gourmet granola Aphrodisiac', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088336600018.jpg' },
  { departmentId: '8', id: '330', nameFr: 'Avoine croquant Trio Petits Fruits', nameEn: 'Oatmeal Crisp Triple Berry', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563315818.jpg' },
  { departmentId: '8', id: '331', nameFr: 'Céréale euphoriques', nameEn: 'Euphoric Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088336600022.jpg' },
  { departmentId: '8', id: '332', nameFr: 'Céréale Raisin Bran', nameEn: 'Raisin Bran Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410010802.jpg' },
  { departmentId: '8', id: '333', nameFr: 'Céréale Corn Flakes', nameEn: 'Corn Flakes Cereal', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410010817.jpg' },
  { departmentId: '8', id: '469', nameFr: 'Quaker Croque Nature Miel Noix', nameEn: 'Honey Nut Harvest Crunch Quaker', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710543.jpg' },
  { departmentId: '8', id: '470', nameFr: 'Muslix saveur erable noix', nameEn: 'Muslix Maple Raisin Nut', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410017616.jpg' },
  { departmentId: '8', id: '481', nameFr: 'Muslix Raisins secs et amandes', nameEn: 'Muslix Almond Raisin', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006410000159.jpg' },
  { departmentId: '8', id: '334', nameFr: 'Champignons tranchés Compliment', nameEn: 'Sliced Mushrooms Compliment', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574236011.jpg' },
  { departmentId: '8', id: '335', nameFr: 'Chapleure biscuits graham au miel', nameEn: 'Honey Maid Crumbs', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672101082.jpg' },
  { departmentId: '8', id: '336', nameFr: 'Chataignes eau tranchées', nameEn: 'Sliced Water Chestnuts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578908111.jpg' },
  { departmentId: '8', id: '337', nameFr: 'Citronnelle dans eau', nameEn: 'In Water Lemon Grass', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578906673.jpg' },
  { departmentId: '8', id: '338', nameFr: 'V8 cocktail aux legumes (6)', nameEn: 'vegetale cocktail V8(6)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321105570.jpg' },
  { departmentId: '8', id: '339', nameFr: 'Barres granola chocolat MadeGood', nameEn: 'Chocolat chip Granola Bars MadeGood', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0068745621301.jpg' },
  { departmentId: '8', id: '340', nameFr: 'Barres granola chocolat bananes MadeGood', nameEn: 'Chocolate Banana Granola Bars MadeGood', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0068745622301.jpg' },
  { departmentId: '8', id: '341', nameFr: 'Coeurs artichaut Pastene', nameEn: 'Artichoke hearts Pastene', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006608605389.jpg' },
  { departmentId: '8', id: '342', nameFr: 'Coffee Mate original', nameEn: 'Original Coffee Mate', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005000036158.jpg' },
  { departmentId: '8', id: '343', nameFr: 'Compote de pomme GoGoSqueez', nameEn: 'Apple Sauce GoGoSqueez', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089000000120.jpg' },
  { departmentId: '8', id: '344', nameFr: 'Compote de pomme sucrée Compliment', nameEn: 'Sweetened Apple Sauce Compliment', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574253359.jpg' },
  { departmentId: '8', id: '345', nameFr: 'Compote de pomme non sucrée Motts', nameEn: 'UnSweetened Apple Sauce Motts', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591200034.jpg' },
  { departmentId: '8', id: '346', nameFr: 'Compote de pomme non sucrée Motts (6)', nameEn: 'UnSweetened Apple Sauce Motts(6)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591200502.jpg' },
  { departmentId: '8', id: '347', nameFr: 'Compote de pomme original Motts (6)', nameEn: ' Original Apple Sauce Motts(6)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591200500.jpg' },
  { departmentId: '8', id: '348', nameFr: 'Compote de peche', nameEn: ' Peach Compote', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008870207725.jpg' },
  { departmentId: '8', id: '349', nameFr: 'Compote de rhubarbe', nameEn: ' Rhubarb Compote', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008870207726.jpg' },
  { departmentId: '8', id: '350', nameFr: 'Compote d abricots', nameEn: ' Abricot Compote', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008870207724.jpg' },
  { departmentId: '8', id: '481', nameFr: 'Compliment Collation aux pommes NS', nameEn: 'Unsweetened Apple snack cups Compliment', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574237187.jpg' },
  { departmentId: '8', id: '482', nameFr: 'Compliment Collation aux pommes framboise', nameEn: 'Raspberry Apple snack cups Compliment', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574254597.jpg' },
  { departmentId: '8', id: '351', nameFr: 'Maggie Arome Liquide', nameEn: 'Maggie Liquid Seasoning', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006707300860.jpg' },
  { departmentId: '8', id: '352', nameFr: 'El-ma-mia sauce Spaghetti a la viande', nameEn: 'El-ma-mia Spaghetti Meat sauce', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005741316989.jpg' },
  { departmentId: '8', id: '353', nameFr: 'Biscuits soda salée', nameEn: 'Premium Plus Salt Crackers', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100783.jpg' },
  { departmentId: '8', id: '354', nameFr: 'Biscuits soda salée', nameEn: 'Premium Plus Salt Crackers', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100783.jpg' },
  { departmentId: '8', id: '355', nameFr: 'Craquelins original Ritz', nameEn: 'Original Ritz', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100220.jpg' },
  { departmentId: '8', id: '356', nameFr: 'Craquelins original Mince aux légumes', nameEn: 'Vegetable Thin Original Cracker', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672102043.jpg' },
  { departmentId: '8', id: '357', nameFr: 'Craquelins fromage mini Ritz', nameEn: 'Mini Ritz Cheese Cracker', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100216.jpg' },
  { departmentId: '8', id: '487', nameFr: 'Craquelins Triscuit faible sodium', nameEn: 'Triscuit Low Sodium Crackers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100746.jpg' },
  { departmentId: '8', id: '488', nameFr: 'Craquelins a leau Carrs', nameEn: 'Table Water Crackers Carrs', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005929057322.jpg' },
  { departmentId: '8', id: '489', nameFr: 'Craquelins Original Breton', nameEn: 'Breton Crackers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005565367020.jpg' },
  { departmentId: '8', id: '490', nameFr: 'Craquelins grain de lin Breton', nameEn: 'Breton with Flax Crackers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005565368800.jpg' },
  { departmentId: '8', id: '491', nameFr: 'Craquelins au cheddar Goldfish', nameEn: 'Goldfish Cheddar Crackers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001410019790.jpg' },
  { departmentId: '8', id: '492', nameFr: 'Craquelins bagel au sesame', nameEn: 'Sesame Bagel Crisps', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008136336570.jpg' },
  { departmentId: '8', id: '493', nameFr: 'Fins au Ble Multigrain', nameEn: 'Wheat Thins Multigrain', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100259.jpg' },
  { departmentId: '8', id: '494', nameFr: 'Craquelins Triscuit Originaux', nameEn: 'Triscuit Original Crackers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006672100742.jpg' },
  { departmentId: '8', id: '444', nameFr: 'Clubhouse epices pour bifteck', nameEn: 'Clubhouse Steak Spice', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620002089.jpg' },
  { departmentId: '8', id: '445', nameFr: 'Clubhouse epices au citron & fines herbes', nameEn: 'Clubhouse Lemon & Herbes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620000255.jpg' },
  { departmentId: '8', id: '446', nameFr: 'Clubhouse Assaisonnement grec', nameEn: 'Clubhouse Greek Seasoning', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620000254.jpg' },
  { departmentId: '8', id: '447', nameFr: 'Clubhouse Assaisonnement pour Poulet de Montreal', nameEn: 'Clubhouse LaGrille Montreal Chicken', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620002090.jpg' },
  { departmentId: '8', id: '448', nameFr: 'Viva Batonnets aux legumes', nameEn: 'Viva Veggies Original', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006290700495.jpg' },
  { departmentId: '8', id: '449', nameFr: 'Bicarbonate de soude pour frigos', nameEn: 'Flo-Thru Deodorizer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006533300171.jpg' },
  { departmentId: '8', id: '452', nameFr: 'Lotus Biscuits Biscoff', nameEn: 'Lotus Biscoff Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0541012671601.jpg' },
  { departmentId: '8', id: '453', nameFr: 'Peek Freans Biscuits Sables', nameEn: 'Peek Freans Shortcake Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006598720426.jpg' },
  { departmentId: '8', id: '454', nameFr: 'Walkers Biscuits Sables en batonnets', nameEn: 'Walkers Shortbread Cookies', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003904700115.jpg' },
  { departmentId: '8', id: '455', nameFr: 'Walkers Biscuits Sables des Highlands', nameEn: 'Walkers Shortbread des Highlands', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003904700144.jpg' },
  { departmentId: '8', id: '466', nameFr: 'MLord Capres', nameEn: 'Mlord Non Pareilles Capers', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578901170.jpg' },
  { departmentId: '8', id: '467', nameFr: 'Cassonade ancienne 1kg', nameEn: 'Old Fashion Brown Sugar', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005889165231.jpg' },
  { departmentId: '8', id: '468', nameFr: 'Cassonade brillante 1kg', nameEn: 'Brilliant Yellow Sugar', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005889160231.jpg' },
  { departmentId: '8', id: '471', nameFr: 'Cerises rouges au marasquin', nameEn: 'Maraschino Cherries Coronation', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006712314839.jpg' },
  { departmentId: '8', id: '472', nameFr: 'Chapelure Panko', nameEn: 'Panko Bread Crumbs', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004139005003.jpg' },
  { departmentId: '8', id: '473', nameFr: 'Chocolat a cuisson mi-sucre', nameEn: 'Bakers semi sweet Chocolat', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006618800800.jpg' },
  { departmentId: '8', id: '474', nameFr: 'Chocolat a cuisson noir 70%cacao', nameEn: 'Bakers 70% Dark Chocolat', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006618800871.jpg' },
  { departmentId: '8', id: '475', nameFr: 'Chocolat a cuisson non sucre', nameEn: 'Bakers Unsweetened Chocolat', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006618800840.jpg' },
  { departmentId: '8', id: '476', nameFr: 'Chutney de mangues doux', nameEn: 'Sweet Mango Chutney', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006927632003.jpg' },
  { departmentId: '8', id: '477', nameFr: 'Kuhne Choucroute traditionnelle', nameEn: 'Kuhne Barrel Sauerkraut', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003904505055.jpg' },
  { departmentId: '8', id: '478', nameFr: 'V8 Cocktail aux legumes 1,89l', nameEn: 'V8 Vegetable Cocktail Juice', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006321106992.jpg' },
  { departmentId: '8', id: '479', nameFr: 'Cocktail de fruits dans sirop 796ml', nameEn: 'Fruit Cocktail in sirop 796ml', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574250500.jpg' },
  { departmentId: '8', id: '480', nameFr: 'Compliment Olives farcies', nameEn: 'Manzanilla Stuffed Olives Compliment', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574235632.jpg' },
  { departmentId: '8', id: '482', nameFr: 'Confiture Bonne Maman', nameEn: 'Bonne Maman Jam', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008870201645.jpg' },
  { departmentId: '8', id: '483', nameFr: 'Confiture de Fraises Comtes de Provence', nameEn: 'Comtes de Provence Strawberry Jam', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0332449999007.jpg' },
  { departmentId: '8', id: '484', nameFr: 'Cornichons Original Maille', nameEn: 'Gerkins Original Maille', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0872270005310.jpg' },
  { departmentId: '8', id: '485', nameFr: 'Couscous Tipiak', nameEn: 'Couscous Tipiak', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008189300300.jpg' },
  { departmentId: '8', id: '486', nameFr: 'Crabe en chair 15% de pattes', nameEn: '15% Leg Meat Crabmeat', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006136244490.jpg' },
  { departmentId: '8', id: '495', nameFr: 'Crevettes cocktail Cloverleaf', nameEn: 'Cocktail Shrimp Cloverleaf', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006136244370.jpg' },
  { departmentId: '8', id: '513', nameFr: 'Champignons tranches Compliment', nameEn: 'Sliced Mushrooms Compliment', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574236011.jpg' },
  { departmentId: '8', id: '514', nameFr: 'Motts Clamato original 1,89L', nameEn: 'Motts Clamato Original', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591200620.jpg' },
  { departmentId: '8', id: '515', nameFr: 'Smuckers Confiture de Fraises ', nameEn: 'Smuckers Strawberry Jam', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005150002580.jpg' },
  { departmentId: '8', id: '516', nameFr: 'Dora Confiture de Fraises ', nameEn: 'Dora Strawberry Jam', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006481932717.jpg' },
  { departmentId: '8', id: '517', nameFr: 'Habitant Cornichons Sucres', nameEn: 'Habitant Sweet Gherkins Pickles', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005900000753.jpg' },
  { departmentId: '8', id: '518', nameFr: 'Habitant Cornichons Surs', nameEn: 'Habitant Sour Gherkins Pickles', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005900000754.jpg' },
  { departmentId: '8', id: '519', nameFr: 'LeLarge Creme de marrons', nameEn: 'LeLarge Chesnut Spread', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005760053202.jpg' },
  { departmentId: '8', id: '520', nameFr: 'MissVickies Original', nameEn: 'MissVickies Original', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041004666.jpg' },
  { departmentId: '8', id: '521', nameFr: 'Ruffles Original', nameEn: 'Ruffles Original', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041004715.jpg' },
  { departmentId: '8', id: '522', nameFr: 'Lays Classique', nameEn: 'Lays Classique', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041004701.jpg' },
  { departmentId: '8', id: '523', nameFr: 'Lays Legere Salee', nameEn: 'Lays Light Salted', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041004711.jpg' },
  { departmentId: '8', id: '524', nameFr: 'Kettle Chips au sel marin', nameEn: 'Kettle Chips Sea Salt', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008411411903.jpg' },
  { departmentId: '8', id: '525', nameFr: 'Kettle Chips au miel et au dijon', nameEn: 'Kettle Chips Honey Dijon', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008411411905.jpg' },
  { departmentId: '8', id: '526', nameFr: 'CapeCod Chips Original', nameEn: 'Cape Cod Croustilles Original', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002068500045.jpg' },
  { departmentId: '8', id: '527', nameFr: 'Tostitos Scoops', nameEn: 'Tostitos Scoops', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041090117.jpg' },
  { departmentId: '8', id: '528', nameFr: 'Tostitos Rondes', nameEn: 'Tostitos Rounds', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041090124.jpg' },
  { departmentId: '8', id: '529', nameFr: 'Croutons cesar classique Grissol', nameEn: 'Cesar Croutons Grissol', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005695145111.jpg' },
  { departmentId: '8', id: '530', nameFr: 'Dattes denoyautees Jaffa', nameEn: 'Pitted Dates Jaffa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006100075110.jpg' },
  { departmentId: '8', id: '531', nameFr: 'Detachant pour tapis Spot Shot', nameEn:'Carpet Stain Remover Spot Shot' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007440510927.jpg' },
  { departmentId: '8', id: '532', nameFr: 'Detergent a lave vaisselle Original Palmolive', nameEn:'Palmolive Original Dish Liquid' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003500046303.jpg' },
  { departmentId: '8', id: '533', nameFr: 'Palmolive Ultra Strength', nameEn:'Palmolive Ultra Strength' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003500045118.jpg' },
  { departmentId: '8', id: '534', nameFr: 'Detergent a lessive liquide GAIN', nameEn:'Laundry Detergent GAIN' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003700012786.jpg' },
  { departmentId: '8', id: '535', nameFr: 'Detergent a lessive liquide aux fleurs des champs Attitude', nameEn:'Wld Flower Laundry Detergent Attitude ' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062623212070.jpg' },
  { departmentId: '8', id: '536', nameFr: 'Detergent a lessive liquide aux zestes argumes Attitude', nameEn:'Citrus Zest Laundry Detergent Attitude ' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062623212072.jpg' },
  { departmentId: '8', id: '537', nameFr: 'Tide Pods Original', nameEn:'Tide Liquid Pods Original' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003700093043.jpg' },
  { departmentId: '8', id: '538', nameFr: 'Clorox eau Javel original', nameEn:'Clorox Original Liquid Bleach' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005550001726.jpg' },
  { departmentId: '8', id: '539', nameFr: 'Extrait vanille artificiel', nameEn:'Artificial Vanilla Extract' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620000604.jpg' },
  { departmentId: '8', id: '540', nameFr: 'Extrait vanille pur', nameEn:'Pure Vanilla Extract' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006620000463.jpg' },
  { departmentId: '8', id: '541', nameFr: 'Farine blanche tout usage Five Roses 2.5kg', nameEn:'All Purpose Flour Five Roses 2.5kg' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005150041042.jpg' },
  { departmentId: '8', id: '542', nameFr: 'Farine blanche tout usage Robin Hood 2.5kg', nameEn:'All Purpose Flour Robin Hood 2.5kg' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0005900001658.jpg' },
  { departmentId: '8', id: '543', nameFr: 'Farine tout usage sans gluten', nameEn:'All Purpose Gluten Free Flour ' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067253151015.jpg' },
  { departmentId: '8', id: '544', nameFr: 'Fecule de mais Fleischmanns', nameEn:'Canada Corn Starch Fleischmanns' , imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0076172098995.jpg' },
  { departmentId: '8', id: '547', nameFr: 'SuperSlim RiceCrisps sésame noir 100gr', nameEn: 'SuperSlim Black Sesame RiceCrisps 100gr', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0068635281661.jpg' },
  { departmentId: '8', id: '548', nameFr: 'SuperSlim RiceCrisps sel de mer 100gr', nameEn: 'SuperSlim Sea Salt RiceCrisps 100gr', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0068635281665.jpg' },
  { departmentId: '8', id: '549', nameFr: 'Belsoy Dessert Noix de coco 4x125gr', nameEn: 'Coconut Gluten Free Dessert Belsoy', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067449111185.jpg' },
  { departmentId: '8', id: '550', nameFr: 'Belsoy Dessert au caramel 4x125gr', nameEn: 'Creamy Caramel Dessert Belsoy', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067449111179.jpg' },
  { departmentId: '8', id: '551', nameFr: 'Belsoy Dessert au chocolat noir 4x125gr', nameEn: 'Dark Chocolat Dessert Belsoy', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067449111177.jpg' },
  { departmentId: '8', id: '552', nameFr: 'Detergent a lave vaisselle Finish Quantum Max 45un', nameEn: 'Finish Quantum Max Dishwasher Detergent', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005170094085.jpg' },
  { departmentId: '8', id: '553', nameFr: 'Detergent a lave vaisselle Finish Quantum 30un', nameEn: 'Finish Quantum Fresh Detergent 30un', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005170020402.jpg' },
  { departmentId: '8', id: '554', nameFr: 'Arctic Power Detergent fraicheur cascade 1.8l', nameEn: 'Arctic Power Waterfall Fresh Detergent', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062884134007.jpg' }, 
 { departmentId: '8', id: '566', nameFr: 'Equal Educorant en sachets', nameEn: 'Equal Packets Sweetner', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081675700003.jpg' },
 { departmentId: '8', id: '567', nameFr: 'Clark Feve au lard', nameEn: 'Clark Pork and Molasses Dark beans', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006430022860.jpg' },
 { departmentId: '8', id: '568', nameFr: 'Clark Feve a la sauce tomate', nameEn: 'Clark Tomato sauce baked beans', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006430022245.jpg' },
 { departmentId: '8', id: '569', nameFr: 'Clark Feve a l ancienne', nameEn: 'Clark old fashioned with pork baked beans', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006430022300.jpg' },
 { departmentId: '8', id: '570', nameFr: 'Clark Feve au lard au sirop erable', nameEn: 'Clark Maple Syrup baked beans', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006430022759.jpg' },
 { departmentId: '8', id: '571', nameFr: 'Fleur de sel Saunier de Camargue', nameEn: 'Sea Salt Fleur de Sel Saunier de Camargue', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007946200055.jpg' },
 { departmentId: '8', id: '572', nameFr: 'Flocons de jambon MapleLeaf ', nameEn: 'Flakes of Ham MapleLeaf', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006310023150.jpg' },
 { departmentId: '8', id: '573', nameFr: 'Flocons de dinde MapleLeaf ', nameEn: 'Flakes of Turkey MapleLeaf', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006310023152.jpg' },
 { departmentId: '8', id: '574', nameFr: 'Galettes de riz au fromage cheddar blanc', nameEn: 'White cheddar Quaker Rice Cakes', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710787.jpg' },
 { departmentId: '8', id: '575', nameFr: 'Galettes de riz nature', nameEn: 'Original Quaker Rice Cakes', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710779.jpg' },
 { departmentId: '8', id: '576', nameFr: 'Betty Crocker Glacage fouetté', nameEn: 'Betty Crocker Cream Cheese Whipped', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006563307437.jpg' },
 { departmentId: '8', id: '577', nameFr: 'Crisco Graisse vegetale', nameEn: 'Crisco vegetable Shortening', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005150038285.jpg' },
 { departmentId: '8', id: '578', nameFr: 'Quaker Gruau rapide', nameEn: 'Quaker Quick Oats', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710110.jpg' },
 { departmentId: '8', id: '579', nameFr: 'Guimauves grosses Compliment', nameEn: 'Large Marshmallows Compliment', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574250151.jpg' },
 { departmentId: '8', id: '580', nameFr: 'Haricots blancs Primo', nameEn: 'White Kidney Beans Primo', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005590000399.jpg' },
 { departmentId: '8', id: '581', nameFr: 'Haricots blancs secs Thompsons', nameEn: 'Dried white Beans Thompsons', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005553611112.jpg' },
 { departmentId: '8', id: '582', nameFr: 'Haricots noirs Primo', nameEn: 'Black Beans Primo', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005590000690.jpg' },
 { departmentId: '8', id: '583', nameFr: 'Haricots rouges Primo', nameEn: 'Red Kidney Beans Primo', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005590000396.jpg' },
 { departmentId: '8', id: '584', nameFr: 'Haricots rouges secs Thompsons', nameEn: ' Dry Red Kidney Beans Thompsons', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005553621112.jpg' },
 { departmentId: '8', id: '586', nameFr: 'Huile de Canola pure 3L', nameEn: '100% Pure Canola Oil', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574250798.jpg' },
 { departmentId: '8', id: '587', nameFr: 'Huile olive extra leger Bertolli 1L', nameEn: 'Extra Light Olive Oil Bertolli 1L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004179000430.jpg' },
 { departmentId: '8', id: '588', nameFr: 'Huile olive extra vierge Delicat Bertolli 1L', nameEn: 'Extra Virgin Mild Taste Olive Oil Bertolli 1L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004179000670.jpg' },
 { departmentId: '8', id: '589', nameFr: 'Huile olive extra vierge original Bertolli 1L', nameEn: 'Extra Virgin Rich Taste Olive Oil Bertolli 1L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004179000210.jpg' },
 { departmentId: '8', id: '590', nameFr: 'Huile olive extra vierge pure Pastene 500ml', nameEn: 'Extra Virgin Olive Oil Pastene 500ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006608603251.jpg' },
 { departmentId: '8', id: '591', nameFr: 'Huile olive pure Pastene 500ml', nameEn: 'Pure Olive Oil Pastene 500ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006608603152.jpg' },
 { departmentId: '8', id: '592', nameFr: 'Huitres fumées Clover Leaf', nameEn: 'Smoked Oysters Clover Leaf',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006136244430.jpg' },
 { departmentId: '8', id: '594', nameFr: 'Jus de citron Realemon 945ml', nameEn: 'Lemon Juice Realemon 945ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591258121.jpg' },
 { departmentId: '8', id: '740', nameFr: 'Finish Jet Dry', nameEn: 'Finish Jet Dry', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005170076206.jpg' },
 { departmentId: '8', id: '741', nameFr: 'Fleecy Assoupli Air Frais', nameEn: 'Fleecy Fresh Air Softener', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003500097397.jpg' }, 
 { departmentId: '8', id: '742', nameFr: 'Lesieur Vinaigrette echalotte', nameEn: 'Lesieur Shallots Dressing', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0326547521600.jpg' },
 { departmentId: '8', id: '743', nameFr: 'Delmonte Bettrave en des', nameEn: 'Delmonte Diced Beets', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006000088804.jpg' },
 { departmentId: '8', id: '744', nameFr: 'Grissol Baguettes biscottes tomate basilic', nameEn: 'Grissol Tomato Basilic Baguettes', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005695144942.jpg' },
 { departmentId: '8', id: '745', nameFr: 'Cerises Bing denoyautees', nameEn: 'MLord Bing Cherry', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578901390.jpg' },
 { departmentId: '8', id: '747', nameFr: 'St Dalfour confiture de fraises', nameEn: 'St Dalfour Strawberry Jam', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008438095744.jpg' },
 { departmentId: '8', id: '748', nameFr: 'Cornets a creme glacee sucres', nameEn: 'Sugar Ice Crean Cones', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574250317.jpg' },
 { departmentId: '8', id: '749' , nameFr: 'Cheetos Puffs 280gr', nameEn: 'Cheetos Puffs 280gr', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006041002728.jpg' },
 { departmentId: '8', id: '750' , nameFr: 'Wasa Seigle et Leger', nameEn: 'Wasa Light Rye',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004113800700.jpg'  },
 { departmentId: '8', id: '753' , nameFr: 'Accent Exhausteur de gout', nameEn: 'Accent Flavor Enhancer Seaoning',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004780000092.jpg'  },
 { departmentId: '8', id: '754' , nameFr: 'Thon pale entier dans eau Rio Mare 3x80gr', nameEn: 'Solid Light in water Rio Mare 3x80gr',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0800403034156.jpg'  },
 { departmentId: '8', id: '755' , nameFr: 'Nutella 375gr', nameEn: 'Nutella Spread 375gr',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006202000074.jpg'  },
 { departmentId: '8', id: '756' , nameFr: 'Cheez Whiz 450gr', nameEn: 'Cheez Whiz 450gr',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006810089231.jpg'  },
 { departmentId: '8', id: '757' , nameFr: 'Stefano tartinade noisette cacao', nameEn: 'Stefano Hazelnut cacao',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006481910700.jpg'  },
 { departmentId: '8', id: '758' , nameFr: 'Marmelade extra orange Shirriff', nameEn: 'Extra Orange Marmalade Shirriff',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005150003850.jpg'  },
 { departmentId: '8', id: '760' , nameFr: 'Gruau Instantane Peche et creme', nameEn: 'Peche and Cream Instant Oatmeal',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557711304.jpg'  },
 { departmentId: '8', id: '761' , nameFr: 'Gruau Instantane regulier', nameEn: 'Regular Instant Oatmeal',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557711301.jpg'  },
 { departmentId: '8', id: '762' , nameFr: 'Gruau Instantane Sucre brun Erable', nameEn: 'Maple Brown Sugar Instant Oatmeal',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557711302.jpg'  },
 { departmentId: '8', id: '771' , nameFr: 'Litiere agglomerante pour chat Compliment 15kg', nameEn: 'Compliment Scoopable Unscented Cat Litter 15kg',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574250874.jpg'  },
 { departmentId: '8', id: '772' , nameFr: 'Litiere pour chat parfumee Saular 8.1kg', nameEn: 'Saular Scented Cat Litter 8.1kg',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006100500625.jpg'  },
 { departmentId: '8', id: '773' , nameFr: 'Litiere pour chat plus Saular 7kg', nameEn: 'Saular Cat Litter 7kg',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006100500901.jpg'  },
 { departmentId: '8', id: '774' , nameFr: 'Mayonnaise Vraie en bouteille comprimable', nameEn: 'Mayo Real Squeeze dressing',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006840050865.jpg'  },
 { departmentId: '8', id: '775' , nameFr: 'Hellmanns Mayonnaise Vraie 445ml', nameEn: 'Hellmanns Real Mayo 445ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006840066210.jpg'  },
 { departmentId: '8', id: '776' , nameFr: 'Hellmanns Mayonnaise legere 445ml', nameEn: 'Hellmanns Mayo 1/2 the fat 445ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006840066220.jpg'  },
 { departmentId: '8', id: '777' , nameFr: 'Melange a biscuits avoine', nameEn: 'Oat Cookie Mix',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710494.jpg'  },
 { departmentId: '8', id: '778' , nameFr: 'Melange a muffins aux brisures de chocolat', nameEn: 'Oatmeal Chocolate chip Muffin Mix',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005557710486.jpg'  },
 { departmentId: '8', id: '779' , nameFr: 'Melange sauce Bechamel LeLarge', nameEn: 'Bechamel Sauce Mix LeLarge',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100206.jpg'  },
 { departmentId: '8', id: '780' , nameFr: 'Melange sauce poutine St Hubert', nameEn: 'Poutine Sauce Mix St Hubert',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100034.jpg'  },
 { departmentId: '8', id: '781' , nameFr: 'Melange sauce a ragout St Hubert', nameEn: 'Ragout Sauce Mix St Hubert',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100008.jpg'  },
 { departmentId: '8', id: '782' , nameFr: 'Melange sauce au boeuf bourguignon St Hubert', nameEn: 'Beef Bourguignon Sauce Mix St Hubert',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100048.jpg'  },
 { departmentId: '8', id: '783' , nameFr: 'Melange sauce barbecue St Hubert', nameEn: 'BBQ Sauce Mix St Hubert',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100001.jpg'  },
 { departmentId: '8', id: '784' , nameFr: 'Melange sauce aux oignons St Hubert', nameEn: 'Onion Sauce Mix St Hubert',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006670100011.jpg'  },
 { departmentId: '8', id: '785' , nameFr: 'Maille Moutard a ancienne 200ml', nameEn: 'Old Fashioned Mustard Maille',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004364620758.jpg'  },
 { departmentId: '8', id: '786' , nameFr: 'Moutard Dijon Amora', nameEn: 'Dijon Mustard Amora',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0871256642754.jpg'  },
 { departmentId: '8', id: '787' , nameFr: 'Maille Moutard Dijon 200ml', nameEn: 'Dijon Mustard Maille 200ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004364620128.jpg'  },
 { departmentId: '8', id: '788' , nameFr: 'Frenchs Moutard jaune', nameEn: 'Yellow Squeeze Mustard Frenchs ',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005620076217.jpg'  },
 { departmentId: '8', id: '789' , nameFr: 'Hertel Nettoyant 1L', nameEn: 'Hertel Refill Cleaner 1L',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006426708522.jpg'  },
 { departmentId: '8', id: '790' , nameFr: 'Vim Nettoyant en creme au citron', nameEn: 'Vim Lemon Cream Cleaner',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006723821229.jpg'  },
 { departmentId: '8', id: '791' , nameFr: 'M.Net Nettoyant liquide aux agrumes ete', nameEn: 'MrClean Summer Citrus Liquid Cleaner',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0003700077131.jpg'  },
 { departmentId: '8', id: '792' , nameFr: 'M.Net Nettoyant liquide au lin et au soleil', nameEn: 'MrClean Linen Sky Liquid Cleaner',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0003700078767.jpg'  },
 { departmentId: '8', id: '793' , nameFr: 'Hertel Nettoyant liquide Multi', nameEn: 'Hertel Multi Purpose Cleaner',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006426708511.jpg'  },
 { departmentId: '8', id: '794' , nameFr: 'Lysol Nettoyant puissant pour la cuvette 700ml', nameEn: 'Lysol Toilet Bowl Cleaner',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005963150814.jpg'  },
 { departmentId: '8', id: '795' , nameFr: 'Detachant multiusage Oxiclean', nameEn: 'MultiPurpose Stain Remove Oxiclean',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0075703751304.jpg'  },
 { departmentId: '8', id: '796' , nameFr: 'Fromage parmesan rape Kraft 250gr', nameEn: 'Grated Parmesan cheese Kraft',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810000453.jpg'  },
 { departmentId: '8', id: '797' , nameFr: 'Gateries pour chiens Milk Bone', nameEn: 'Flavoured snacks dog biscuits Milk Bone',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0087180200641.jpg'  },
 { departmentId: '8', id: '798' , nameFr: 'Gaufrettes au cafe et chocolat au lait Leclerc', nameEn: 'Coffee Coadted Celebration Wafer Leclerc',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006404200665.jpg'  },
 { departmentId: '8', id: '799' , nameFr: 'Huile de sesame Blue Dragon', nameEn: 'Sesame Oil Blue Dragon',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067277401395.jpg'  },
 { departmentId: '8', id: '800' , nameFr: 'Ketchup aux tomates Heinz', nameEn: 'Squeeze Ketchup Heinz',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005700001316.jpg'  },
 { departmentId: '8', id: '801' , nameFr: 'Lait de coco Haiku', nameEn: 'Coconut Milk Haiku',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578900667.jpg'  },
 { departmentId: '8', id: '802' , nameFr: 'Lentilles rouge', nameEn: 'Red Lentils',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574252489.jpg'  },
 { departmentId: '8', id: '803' , nameFr: 'Lentilles vertes', nameEn: 'Green Lentils',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574253720.jpg'  },
 { departmentId: '8', id: '804' , nameFr: 'Litchi entiers MLord', nameEn: 'In Syrup Lychees MLord',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578900138.jpg'  },























  { departmentId: '9', id: '358', nameFr: 'Coors Lime en cannette', nameEn: 'Coors Slice Lime Cans', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632701539.jpg' },
  { departmentId: '9', id: '359', nameFr: 'Bitburger sans alcool (4x500ml)', nameEn: 'Bitburger non alcoholic (4x500ml)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0002050700067.jpg' },
  { departmentId: '9', id: '360', nameFr: 'St-Ambroise Session IPA (6x341ml)', nameEn: 'St-Ambroise Session IPA (6x341ml)', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005548030007.jpg' },
  { departmentId: '9', id: '361', nameFr: 'St-Ambroise Double IPA cannette 473ml', nameEn: 'St-Ambroise Double IPA 473ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005548030005.jpg' },
  { departmentId: '9', id: '362', nameFr: 'Belle Gueule Rousse en cannette', nameEn: 'Belle Gueule Can Rousse', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0077621390005.jpg' },
  { departmentId: '9', id: '363', nameFr: 'Rickards Red en cannette', nameEn: 'Rickards Red Can', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632700494.jpg' },
  { departmentId: '9', id: '451', nameFr: 'Heineken sans alcool 6x330ml', nameEn: 'Heineken 0% Canned Alcohol Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007289000588.jpg' },
  { departmentId: '9', id: '496', nameFr: 'Castor Yakima IPA 473ml', nameEn: 'Castor Yakima IPA 473ml', imageUrl: 'https://www.microlecastor.ca/wp-content/uploads/2016/03/yakima-red.jpg' },
  { departmentId: '9', id: '497', nameFr: 'Castor West Coast 473ml', nameEn: 'Castor West Coast 473ml', imageUrl: 'https://www.microlecastor.ca/wp-content/uploads/2018/08/west-coast-red.jpg' },
  { departmentId: '9', id: '497', nameFr: 'Castor New England IPA 473ml', nameEn: 'Castor New England IPA 473ml', imageUrl: 'https://www.microlecastor.ca/wp-content/uploads/2019/07/website-1.jpg' },
  { departmentId: '9', id: '498', nameFr: 'Castor Aussieland 473ml', nameEn: 'Castor Aussieland 473ml', imageUrl: 'https://www.microlecastor.ca/wp-content/uploads/2020/02/website.jpg' },
  { departmentId: '9', id: '499', nameFr: 'Moretti Birra en cannette', nameEn: 'Moretti Birra Beer 4x500ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632701442.jpg' },
  { departmentId: '9', id: '500', nameFr: 'Coors Biologique 12x355ml', nameEn: 'Coors Organic Light Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632701602.jpg' },
  { departmentId: '9', id: '501', nameFr: 'Coors Light 12x355ml', nameEn: 'Coors Light 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632718325.jpg' },
  { departmentId: '9', id: '502', nameFr: 'Biere Blanche 1664 4x500ml', nameEn: 'Blanc Beer 1664 4x500ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0308021604696.jpg' },
  { departmentId: '9', id: '503', nameFr: 'Biere Blanche Belgium Moon', nameEn: 'Belgium Moon Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632701113.jpg' },
  { departmentId: '9', id: '504', nameFr: 'Biere Blanche Chambly 750ml', nameEn: 'Blanche de Chambly Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0077228502111.jpg' },
  { departmentId: '9', id: '505', nameFr: 'Biere blonde Corona Extra', nameEn: 'Light Bottles Beer Corona', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0000106420215.jpg' },
  { departmentId: '9', id: '506', nameFr: 'Biere blonde Modelo Especial', nameEn: 'Modelo Especial Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/7501064196379.jpg' },
  { departmentId: '9', id: '507', nameFr: 'Biere blonde Becks 5%', nameEn: 'Becks 5% Beer', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008248814301.jpg' },
  { departmentId: '9', id: '508', nameFr: 'Biere Stella Artois 6x330ml', nameEn: 'Stella Artois Beer 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0078615000001.jpg' },
  { departmentId: '9', id: '509', nameFr: 'Molson Ultra 12x355ml', nameEn: 'Molson Ultra 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632701382.jpg' },
  { departmentId: '9', id: '510', nameFr: 'Molson Export Ale 12x355ml', nameEn: 'Molson Export Ale 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632700661.jpg' },
  { departmentId: '9', id: '511', nameFr: 'Heineken 5% en cannette 6x330ml', nameEn: 'Heineken 5% 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007289000001.jpg' },
  { departmentId: '9', id: '512', nameFr: 'Molson Dry 5.5% 6x355ml', nameEn: 'Molson Dry 5.5% 6x355ml ', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005632770323.jpg' },
  

 
  { departmentId: '11', id: '364', nameFr: 'Amandes Original Non sucrée BD 946ml', nameEn: 'Unsweetened Original Almond BD 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004157005531.jpg' },
  { departmentId: '11', id: '365', nameFr: 'Boisson de riz a la vanille 946ml', nameEn: 'Vanilla Rice Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366700602.jpg' },
  { departmentId: '11', id: '366', nameFr: 'Boisson de soya au chocolat 946ml', nameEn: 'Chocolat Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366730185.jpg' },
  { departmentId: '11', id: '367', nameFr: 'Boisson de soya a la fraise 946ml', nameEn: 'Strawberry Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366730170.jpg' },
  { departmentId: '11', id: '368', nameFr: 'Boisson de soya au chocolat 3x200ml', nameEn: 'Chocolat Soy Beverage 3x200ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366750801.jpg' },
  { departmentId: '11', id: '369', nameFr: 'Boisson de soya original 946ml', nameEn: 'Original Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366730140.jpg' },
  { departmentId: '11', id: '370', nameFr: 'Boisson de soya légere a la vanille 946ml', nameEn: 'Vanilla Light Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366751050.jpg' },
  { departmentId: '11', id: '371', nameFr: 'Boisson de soya non sucrée 946ml', nameEn: 'Unsweetened Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366751100.jpg' },
  { departmentId: '11', id: '372', nameFr: 'Boisson de soya vanilla 946ml', nameEn: 'Vanilla Soy Beverage 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006366730155.jpg' },
  { departmentId: '11', id: '373', nameFr: 'Breeze amandes original 946ml', nameEn: 'Original Almond Breeze 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004157005272.jpg' },
  { departmentId: '11', id: '374', nameFr: 'Breeze amandes au chocolat  946ml', nameEn: 'Chocolat Almond Breeze 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004157005274.jpg' },
  { departmentId: '11', id: '375', nameFr: 'Breeze amandes au chocolat non sucré  946ml', nameEn: 'Unsweetened Chocolat Almond Breeze 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004157005960.jpg' },
  { departmentId: '11', id: '376', nameFr: 'Breeze amandes a la vanille non sucré  946ml', nameEn: 'Unsweetened Vanilla Almond Breeze 946ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004157005583.jpg' },
  { departmentId: '11', id: '377', nameFr: 'GoGoQuinoa trois coloreur ', nameEn: 'Tri-Color Quinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900077.jpg' },
  { departmentId: '11', id: '378', nameFr: 'Quinoa Blanc ', nameEn: 'White Quinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900081.jpg' },
  { departmentId: '11', id: '379', nameFr: 'Quinoa melange 5 grains ', nameEn: '5 Whole Grains Quinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900083.jpg' },
  { departmentId: '11', id: '380', nameFr: 'Fusili Choufleur', nameEn: 'Cauliflower Fusili', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088532000012.jpg' },
  { departmentId: '11', id: '381', nameFr: 'Fusili Pois chiches', nameEn: 'Chickpea Fusili', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088532000011.jpg' },
  { departmentId: '11', id: '382', nameFr: 'Quinoa Royal Rouge', nameEn: 'Red Grain Quinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900022.jpg' },
  { departmentId: '11', id: '383', nameFr: 'GoGoQuinoa Spaghetti', nameEn: 'Spaghetti GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900002.jpg' },
  { departmentId: '11', id: '384', nameFr: 'GoGoQuinoa Biscuit Orange & Mangue', nameEn: 'Mango Orange Cookies GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900039.jpg' },
  { departmentId: '11', id: '385', nameFr: 'GoGoQuinoa Biscuit pepites de chocolat', nameEn: 'Chocolat Chip Cookies GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900040.jpg' },
  { departmentId: '11', id: '386', nameFr: 'GoGoQuinoa Biscuit double chocolat', nameEn: 'Double Chocolat Cookies GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0089689900046.jpg' },
  { departmentId: '11', id: '387', nameFr: 'GoGoQuinoa Céréales soufflées Quinoa Cacao', nameEn: 'Choco Puffs GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900016.jpg' },
  { departmentId: '11', id: '388', nameFr: 'GoGoQuinoa pate Macaroni', nameEn: 'Macaroni Pasta GoGoQuinoa', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900027.jpg' },
  { departmentId: '11', id: '389', nameFr: 'Melange a crepes 3 grains bio', nameEn: 'Organic 3 Grains pancake mix Bio', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900037.jpg' },
  { departmentId: '11', id: '390', nameFr: 'Soupe aux legumes Biologique', nameEn: 'Organic Vegetable Soupe', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0089689900028.jpg' },
 { departmentId: '11', id: '585', nameFr: 'Hemp Bliss original non sucré', nameEn: 'Unsweetened Original Hemp Bliss', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0069765841017.jpg' },
 { departmentId: '11', id: '759' , nameFr: 'Fusilli Lentilles et Quinoa', nameEn: 'Lentil and Quinoa Fusilli',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0088532000013.jpg'  },




 
  { departmentId: '12', id: '391', nameFr: 'Pepsi 2L', nameEn: 'Pepsi 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006900000261.jpg' },
  { departmentId: '12', id: '392', nameFr: 'Pepsi diete 2L', nameEn: 'Diet Pepsi 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006900001261.jpg' },
  { departmentId: '12', id: '393', nameFr: 'Pepsi mini', nameEn: 'Mini Pepsi', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006900014902.jpg' },
  { departmentId: '12', id: '394', nameFr: 'Pepsi 12x355ml', nameEn: 'Pepsi 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006900000428.jpg' },
  { departmentId: '12', id: '395', nameFr: 'Pepsi diete 12x355ml', nameEn: 'Diet Pepsi 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006900001428.jpg' },
  { departmentId: '12', id: '396', nameFr: 'CanadaDry au gingembre 12x355ml', nameEn: 'CanadaDry Ginger Ale 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000893.jpg' },
  { departmentId: '12', id: '397', nameFr: 'CanadaDry au gingembre diete 12x355ml', nameEn: 'CanadaDry Diet Ginger Ale 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000894.jpg' },
  { departmentId: '12', id: '398', nameFr: 'CanadaDry au gingembre 2L', nameEn: 'CanadaDry Ginger Ale 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000157.jpg' },
  { departmentId: '12', id: '399', nameFr: 'CanadaDry au gingembre mini', nameEn: 'CanadaDry Ginger Ale Mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000144.jpg' },
  { departmentId: '12', id: '400', nameFr: 'CanadaDry Club Soda 12x355ml', nameEn: 'CanadaDry Club Soda 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000895.jpg' },
  { departmentId: '12', id: '401', nameFr: 'CanadaDry Eau tonique mini', nameEn: 'CanadaDry tonic water mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210001040.jpg' },
  { departmentId: '12', id: '402', nameFr: 'CanadaDry Eau tonique 12x355ml', nameEn: 'CanadaDry tonic water 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006210000896.jpg' },
  { departmentId: '12', id: '403', nameFr: 'CocaCola classique 12x355ml', nameEn: 'CocaCola classique 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700010483.jpg' },
  { departmentId: '12', id: '404', nameFr: 'CocaCola classique mini', nameEn: 'CocaCola classique mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700010983.jpg' },
  { departmentId: '12', id: '405', nameFr: 'CocaCola Zero 12x355ml', nameEn: 'CocaCola Zero 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700010665.jpg' }, 
  { departmentId: '12', id: '406', nameFr: 'CocaCola Diete 2L', nameEn: 'CocaCola Diet 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700000897.jpg' },
  { departmentId: '12', id: '407', nameFr: 'CocaCola Zero 2L', nameEn: 'CocaCola Zero 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700010668.jpg' },
  { departmentId: '12', id: '408', nameFr: 'CocaCola Classique 2L', nameEn: 'CocaCola Classique 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700000427.jpg' },
  { departmentId: '12', id: '409', nameFr: 'CocaCola Classique 355ml', nameEn: 'CocaCola Classique 355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700000829.jpg' },
  { departmentId: '12', id: '410', nameFr: 'Schweppes Soda Tonique 2L', nameEn: 'Schweppes Tonic Water 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660000009.jpg' },
  { departmentId: '12', id: '411', nameFr: 'Schweppes au gingembre mini', nameEn: 'Schweppes Ginger Ale mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660000010.jpg' },
  { departmentId: '12', id: '412', nameFr: 'Schweppes club soda mini', nameEn: 'Schweppes Club Soda mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660000011.jpg' },
  { departmentId: '12', id: '413', nameFr: 'Schweppes au gingembre 2L', nameEn: 'Schweppes Ginger Ale 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660000029.jpg' },
  { departmentId: '12', id: '414', nameFr: 'Schweppes soda tonique mini', nameEn: 'Schweppes tonic water mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660000012.jpg' },
  { departmentId: '12', id: '415', nameFr: 'Redbull Original 250ml', nameEn: 'RedBull 250ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0008085400010.jpg' },
  { departmentId: '12', id: '416', nameFr: '7Up 2L', nameEn: '7Up 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006540000073.jpg' },
  { departmentId: '12', id: '417', nameFr: '7Up mini', nameEn: '7Up mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006540014722.jpg' },
  { departmentId: '12', id: '418', nameFr: 'Sprite 2L', nameEn: 'Sprite 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700000506.jpg' },
  { departmentId: '12', id: '419', nameFr: 'Fresca mini', nameEn: 'Fresca mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700011243.jpg' },
  { departmentId: '12', id: '420', nameFr: 'Crush Orange mini', nameEn: 'Crush Orange mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005600000005.jpg' },
  { departmentId: '12', id: '421', nameFr: 'Crush Orange 2L', nameEn: 'Crush Orange 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005600000090.jpg' },
  { departmentId: '12', id: '422', nameFr: 'Fanta Mini', nameEn: 'Fanta Mini', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700011089.jpg' },
  { departmentId: '12', id: '423', nameFr: 'Compliment soda gingembre', nameEn: 'Compliment Ginger Ale', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574233524.jpg' },
  { departmentId: '12', id: '424', nameFr: 'Compliment Club Soda', nameEn: 'Compliment Club Soda', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574233523.jpg' },
  { departmentId: '12', id: '425', nameFr: '7Up Zero 2L', nameEn: '7Up Zero 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006540000074.jpg' },
  { departmentId: '12', id: '426', nameFr: 'Perrier Original 1L', nameEn: 'Perrier Original 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478035600.jpg' },
  { departmentId: '12', id: '427', nameFr: 'Perrier Orange 1L', nameEn: 'Perrier Orange 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478044732.jpg' },
  { departmentId: '12', id: '428', nameFr: 'Perrier Lime 1L', nameEn: 'Perrier Lime 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478044735.jpg' },
  { departmentId: '12', id: '429', nameFr: 'Perrier Pamplemousse 1L', nameEn: 'Perrier Grapefruit 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478044738.jpg' },
  { departmentId: '12', id: '430', nameFr: 'Perrier Fraise 1L', nameEn: 'Perrier Strawberry 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478033606.jpg' },
  { departmentId: '12', id: '431', nameFr: 'Perrier Citron 1L', nameEn: 'Perrier Lemon 1L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478044729.jpg' },
  { departmentId: '12', id: '432', nameFr: 'Perrier Citron 10x250ml', nameEn: 'Perrier Lemon 10x250ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478035596.jpg' },
  { departmentId: '12', id: '433', nameFr: 'Perrier Original 10x250ml', nameEn: 'Perrier Original 10x250ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478091150.jpg' },
  { departmentId: '12', id: '434', nameFr: 'Perrier Original 4x330ml', nameEn: 'Perrier Original 4x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478000006.jpg' },
  { departmentId: '12', id: '435', nameFr: '7Up 12x355ml', nameEn: '7Up 12x355ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006540000159.jpg' },
  { departmentId: '12', id: '436', nameFr: 'San Pellegrino Pompelmo 6x330ml', nameEn: 'San Pellegrino Pompelmo 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150851168.jpg' },
  { departmentId: '12', id: '437', nameFr: 'San Pellegrino Clementine 6x330ml', nameEn: 'San Pellegrino Clementina 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150851188.jpg' },
  { departmentId: '12', id: '438', nameFr: 'San Pellegrino Limonata 6x330ml', nameEn: 'San Pellegrino Limonata 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150880034.jpg' },
  { departmentId: '12', id: '439', nameFr: 'San Pellegrino Melograno Arancia 6x330ml', nameEn: 'San Pellegrino Melograno Arancia 6x330ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150893479.jpg' },
  { departmentId: '12', id: '440', nameFr: 'San Pellegrino Eau minérale 6x250ml', nameEn: 'San Pellegrino Mineral Water 6x250ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150896383.jpg' },
  { departmentId: '12', id: '441', nameFr: 'San Pellegrino Eau minérale 6x500ml', nameEn: 'San Pellegrino Mineral water 6x500ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150892246.jpg' },
  { departmentId: '12', id: '442', nameFr: 'San Pellegrino Eau minérale 750ml', nameEn: 'San Pellegrino Mineral Water 750ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004150880012.jpg' },
  { departmentId: '12', id: '443', nameFr: 'Perrier Eau gazéofiée 750ml', nameEn: 'Perrier Mineral Water 750ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007478000005.jpg' },
  { departmentId: '12', id: '450', nameFr: 'Biere au gingembre 6x250ml', nameEn: 'Ginger Beer 6x250ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005578900952.jpg' },
  { departmentId: '12', id: '456', nameFr: 'Fruite Orange 2L', nameEn: 'Orange Fruite 2L', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731131002.jpg' },
  { departmentId: '12', id: '457', nameFr: 'Fruite aux fraises 2L', nameEn: 'Strawberry Fruite 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731147002.jpg' },
  { departmentId: '12', id: '458', nameFr: 'Fruite aux peches 2L', nameEn: 'Peach Fruite 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731157002.jpg' },
  { departmentId: '12', id: '459', nameFr: 'Fruite aux raisins 2L', nameEn: 'Grape Fruite 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731133002.jpg' },
  { departmentId: '12', id: '460', nameFr: 'Monster Zero Ultra', nameEn: 'Monster Zero Ultra', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007084701520.jpg' },
  { departmentId: '12', id: '461', nameFr: 'DrPepper 2L', nameEn: 'DrPepper 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005490000060.jpg' },
  { departmentId: '12', id: '462', nameFr: 'Schweppes Soda tonique 12x355ml', nameEn: 'Schweppes Soda tonique 12x355ml', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001660010002.jpg' },
  { departmentId: '12', id: '463', nameFr: 'Fanta 2L', nameEn: 'Fanta 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006700000449.jpg' },
  { departmentId: '12', id: '555', nameFr: 'Eska eau source naturelle 15x330ml', nameEn: 'Eska Natural Spring Water 15x330ml', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067178530195.jpg' },
  { departmentId: '12', id: '556', nameFr: 'Eska eau source naturelle 12x500ml', nameEn: 'Eska Natural Spring Water 12x500ml', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067178550182.jpg' },
  { departmentId: '12', id: '557', nameFr: 'Eska eau source naturelle 1.5L', nameEn: 'Eska Natural Spring Water 1.5L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/small/0067178520100.jpg' },
  { departmentId: '12', id: '558', nameFr: 'Naya eau source naturelle 1.5L', nameEn: 'Naya Natural Spring Water 1.5L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005737912105.jpg' },
  { departmentId: '12', id: '559', nameFr: 'Eska eau source gazeifiée 1L', nameEn: 'Eska carbonated Pet Spring Water 1L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0067178510001.jpg' },
  { departmentId: '12', id: '560', nameFr: 'Fiji eau source naturelle 1L', nameEn: 'Fiji Natural Spring Water 1L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0063256500023.jpg' },
  { departmentId: '12', id: '561', nameFr: 'Labrado eau source naturelle 4L', nameEn: 'Labrado Natural Spring Water 4L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005529740001.jpg' },
  { departmentId: '12', id: '751' , nameFr: 'Labrado demineralise 4L', nameEn: 'Labrado Denuberalized water 4L',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005529740002.jpg'  },
  { departmentId: '12', id: '562', nameFr: 'Nestle Purelife eau source petillante gazéifiée lime 1L', nameEn: 'Nestle Purelife Sparkling lime 1L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006827431148.jpg' },
  { departmentId: '12', id: '563', nameFr: 'Nestle Purelife eau source petillante gazéifiée trio fruit 1L', nameEn: 'Nestle Purelife Sparkling Triple berry 1L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006827431140.jpg' },
 { departmentId: '12', id: '564', nameFr: 'Labrado eau source déminéralisée 4L', nameEn: 'Labrado Demineralized Water 4L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005529740002.jpg' },
 { departmentId: '12', id: '565', nameFr: 'Naya eau source naturelle 6x1.5L', nameEn: 'Labrado Natural Spring Water 6x1.5L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005737905879.jpg' },
 { departmentId: '12', id: '593', nameFr: 'Jus de canneberge Oasis 1.36L', nameEn: 'Cranberry Cocktail Oasis 1.36L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731120762.jpg' },
 { departmentId: '12', id: '595', nameFr: 'Jus de pomme royal gala Rougemont', nameEn: 'Royal Gala Apple Juice Rougemont',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731119029.jpg' },
 { departmentId: '12', id: '596', nameFr: 'Jus de pomme Honeycrisp Rougemont', nameEn: 'Honeycrisp Apple Juice Rougemont',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731119024.jpg' },
 { departmentId: '12', id: '597', nameFr: 'Jus de pomme Honeycrisp Rougemont', nameEn: 'Honeycrisp Apple Juice Rougemont',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731119024.jpg' },
 { departmentId: '12', id: '598', nameFr: 'Jus de pommes Oasis 6x300ml', nameEn: 'Oasis Apple Juice 6x300ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731101018.jpg' },
 { departmentId: '12', id: '746', nameFr: 'Rougemont Cocktail raisin 8x200ml', nameEn: 'Rougemont Grape Cocktail 8x200ml', imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731132087.jpg' },
 { departmentId: '12', id: '763' , nameFr: 'Oasis jus mangues exotique 8x200ml', nameEn: 'Oasis Exotic mango Fruit juice 8x200ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731103732.jpg'  },
 { departmentId: '12', id: '764' , nameFr: 'Oasis jus de passion 8x200ml', nameEn: 'Oasis Tropical Passion Fruit juice 8x200ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731104132.jpg'  },
 { departmentId: '12', id: '765' , nameFr: 'Oasis jus orange 8x200ml', nameEn: 'Oasis Orange juice 8x200ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731102032.jpg'  },
 { departmentId: '12', id: '766' , nameFr: 'OasisFruitzoo Fraises et Bananes 8x200ml', nameEn: 'Oasis Strawberry and Banana juice 8x200ml',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731105912.jpg'  },
 { departmentId: '12', id: '767' , nameFr: 'Slim jus de mangues tropicales', nameEn: 'Slim Tropical Mango juice',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005796102683.jpg'  },
 { departmentId: '12', id: '768' , nameFr: 'Slim jus limonade', nameEn: 'Slim limonade juice',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005796102884.jpg'  },
 { departmentId: '12', id: '769' , nameFr: 'Welchs jus de raisin concords', nameEn: 'Welchs Concord Grape juice',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006591200000.jpg'  },
 { departmentId: '12', id: '770' , nameFr: 'Welchs jus de raisin blanc', nameEn: 'Welchs White Grape juice',imageUrl: 'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006591200001.jpg'  },












 


 { departmentId: '13', id: '602', nameFr: 'Quebon Lait au chocolat 1% 2L', nameEn: '1% Chocolat milk Quebon 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0005587210518.jpg' },
 { departmentId: '13', id: '603', nameFr: 'Natrel Lait 1% 2L', nameEn: '1% Milk Natrel 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006442001022.jpg' },
 { departmentId: '13', id: '604', nameFr: 'Natrel Lait 2% 2L', nameEn: '2% Milk Natrel 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006442001012.jpg' },
 { departmentId: '13', id: '605', nameFr: 'Lait sans lactose 1% 2L', nameEn: '1% Lactose Free Milk 2L', imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006442005501.jpg' },
 { departmentId: '13', id: '606', nameFr: 'Lait Biologique 1% 2L', nameEn: '1% Organic Milk 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0005587253517.jpg' },
 { departmentId: '13', id: '607', nameFr: 'Lait sans lactose 2% 2L', nameEn: '2% Lactose Free Milk 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006442025501.jpg' },
 { departmentId: '13', id: '608', nameFr: 'Astro Yogourt nature 2%m.g', nameEn: 'Astro 2% Plain Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005782575002.jpg' },
 { departmentId: '13', id: '609', nameFr: 'Natrel Lait 3,25% 2L', nameEn: 'Natrel 3,25% Milk 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006442001002.jpg' },
 { departmentId: '13', id: '610', nameFr: 'Activia yogourt aux bleuets', nameEn: 'Activia Blueberry Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680034596.jpg' },
 { departmentId: '13', id: '611', nameFr: 'Activia yogourt aux cerises', nameEn: 'Activia Cherry Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680073902.jpg' },
 { departmentId: '13', id: '612', nameFr: 'Activia yogourt aux citrons', nameEn: 'Activia Lemon Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0005680073901.jpg' },
 { departmentId: '13', id: '613', nameFr: 'Activia yogourt nature', nameEn: 'Activia Plain Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680024917.jpg' },
 { departmentId: '13', id: '614', nameFr: 'Activia yogourt a la vanille ', nameEn: 'Activia Vanilla Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0005680009829.jpg' },
 { departmentId: '13', id: '615', nameFr: 'Activia yogourt aux fraises ', nameEn: 'Activia Strawberry Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680009827.jpg' },
 { departmentId: '13', id: '616', nameFr: 'Silk boisson aux amandes a la vanille non sucre', nameEn: 'Silk Almond Unsweetned Vanilla',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002529300188.jpg' },
 { departmentId: '13', id: '617', nameFr: 'Magarine Or Becel', nameEn: 'Becel Gold Margarine',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001111500116.jpg' },
 { departmentId: '13', id: '618', nameFr: 'Iogo au sirop erable', nameEn: 'Iogo Maple Syrup Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0062902541045.jpg' },
 { departmentId: '13', id: '619', nameFr: 'Liberte Yogourt au caramel', nameEn: 'Caramel Mediterranee Yogurt Liberte',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568400623.jpg' },
 { departmentId: '13', id: '620', nameFr: 'Fromage Mi-Fort en tranches Cracker Barrel', nameEn: 'Cheese Medium Slices Cracker Barrel',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006820000641.jpg' },
 { departmentId: '13', id: '621', nameFr: 'Fromage en tranches Marbre', nameEn: 'Marble Cheese Slices',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820000647.jpg' },
 { departmentId: '13', id: '622', nameFr: 'Fromage Cheddar Riviera', nameEn: 'Cheddar White Cheese Riviera',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006491200173.jpg' },
 { departmentId: '13', id: '623', nameFr: 'Liberte Grec Noix de coco 2% 4x100gr', nameEn: 'Liberte Coconuts Greek Yogurt 4x100gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568400417.jpg' },
 { departmentId: '13', id: '624', nameFr: 'Liberte Grec aux cerises 2% 4x100gr', nameEn: 'Liberte Cherry Greek Yogurt 4x100gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006568400418.jpg' },
 { departmentId: '13', id: '625', nameFr: 'Liberte Grec aux fraises 4x100gr', nameEn: 'Liberte Strawberry Greek Yogurt 4x100gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568400538.jpg' },
 { departmentId: '13', id: '626', nameFr: 'Liberte Grec aux citrons 4x100gr', nameEn: 'Liberte Lemon Greek Yogurt 4x100gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568400419.jpg' },
 { departmentId: '13', id: '627', nameFr: 'Liberte Grec a la mangue 4x100gr', nameEn: 'Liberte Mango Greek Yogurt 4x100gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568411283.jpg' },
 { departmentId: '13', id: '628', nameFr: 'Liberte Grec croquant a la vanille', nameEn: 'Liberte Crunch Vanilla Bean Greek Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006568449731.jpg' },
 { departmentId: '13', id: '629', nameFr: 'Yop yogourt a boire aux fraises', nameEn: 'YOP Drinkable Strawberry Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692001201.jpg' },
 { departmentId: '13', id: '630', nameFr: 'Yop yogourt a boire tropical', nameEn: 'YOP Drinkable Tropical Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692011619.jpg' },
 { departmentId: '13', id: '631', nameFr: 'Yop yogourt a boire aux peches', nameEn: 'YOP Drinkable Peach Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692001207.jpg' },
 { departmentId: '13', id: '632', nameFr: 'Yop yogourt a boire aux framboises', nameEn: 'YOP Drinkable Raspberry Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692001202.jpg' },
 { departmentId: '13', id: '633', nameFr: 'Ficello Fromage Cheddar blanc', nameEn: 'Ficello Cheddarific Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006820098918.jpg' },
 { departmentId: '13', id: '634', nameFr: 'Oasis Smoothie a la mangue et au fruit de passion', nameEn: 'Oasis Mango/Passion Fruit Smoothie',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731120112.jpg' },
 { departmentId: '13', id: '635', nameFr: 'Oasis Smoothie a la mangue tropical 1,75L', nameEn: 'Oasis Mango Tropical Smoothie 1,75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731105789.jpg' },
 { departmentId: '13', id: '636', nameFr: 'Oasis Smoothie vert actif 1,75L', nameEn: 'Oasis Greenergy Smoothie 1,75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006731106289.jpg' },
 { departmentId: '13', id: '637', nameFr: 'Liberte Yogourt Grec Nature 0%m.g', nameEn: 'Liberte Plain 0% Greek Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006568400510.jpg' },
 { departmentId: '13', id: '638', nameFr: 'Maclaren Imperial Fromage cheddat fort', nameEn: 'Maclaren Imperial Natural Wheel Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006810089505.jpg' },
 { departmentId: '13', id: '639', nameFr: 'Silk aux amandes a la vanille', nameEn: 'Silk Almond Vanilla Beverage',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002529300168.jpg' },
 { departmentId: '13', id: '640', nameFr: 'Silk aux amandes non sucree', nameEn: 'Silk Unsweetened Almond Beverage',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002529300150.jpg' },
 { departmentId: '13', id: '641', nameFr: 'Silk Soya non sucree', nameEn: 'Silk Unsweetened Soy Beverage',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002529300073.jpg' },
 { departmentId: '13', id: '642', nameFr: 'Silk Soya Original', nameEn: 'Silk Original Soy Beverage',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0002529360071.jpg' },
 { departmentId: '13', id: '643', nameFr: 'Riviera Delice Vegetalien Framboise 4x120gr', nameEn: 'Riviera Vegan Delight Raspberry 4x120gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006491208676.jpg' },
 { departmentId: '13', id: '644', nameFr: 'Delice Vegetalien Lait de Noix de coco 650gr', nameEn: 'Vegan Delight Plain Coconut Milk 650gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006491208722.jpg' },
  { departmentId: '13', id: '645', nameFr: 'Delice Vegetalien Lait de Noix de coco et Vanille 650gr', nameEn: 'Vegan Delight Vanilla Coconut Milk 650gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0006491208723.jpg' },
 { departmentId: '13', id: '646', nameFr: 'Kozyshack Pouding au riz 624gr', nameEn: 'Kozyshack rice pudding 624gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007349151000.jpg' },
 { departmentId: '13', id: '647', nameFr: 'Kozyshack Pouding au tapioca 624gr', nameEn: 'Kozyshack tapioca pudding 624gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0007349153000.jpg' },
 { departmentId: '13', id: '648', nameFr: 'Riviera yogourt vegetal mangue et fruit de passion', nameEn: 'Riviera Vegan Mango & Passion fruit Yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006491208718.jpg' }, 
 { departmentId: '13', id: '649', nameFr: 'Philadelphia Fromage a la creme leger', nameEn: 'Philadelphia Soft Light Cream Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810089634.jpg' }, 
 { departmentId: '13', id: '650', nameFr: 'Philadelphia Fromage a la creme original', nameEn: 'Philadelphia Original Cream Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810089633.jpg' },
 { departmentId: '13', id: '651', nameFr: 'Philadelphia Fromage a la creme au saumon fume', nameEn: 'Philadelphia Smoked Salmon Cream Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810090064.jpg' },
 { departmentId: '13', id: '652', nameFr: 'Cracker Barrel Cheddar fort', nameEn: 'Cracker Barrel Old Cheddar',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820000488.jpg' },
 { departmentId: '13', id: '653', nameFr: 'Riviera Cheddar doux marbre', nameEn: 'Riviera Marble Cheddar Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006491200175.jpg' },
 { departmentId: '13', id: '654', nameFr: 'Kraft Single Fromage tranchee', nameEn: 'Kraft Singles Thick Slices pack cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810000345.jpg' },
 { departmentId: '13', id: '655', nameFr: 'Compliment Melange de fromage rape', nameEn: 'Compliment Pizza Blend Shredded Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574255353.jpg' },
 { departmentId: '13', id: '656', nameFr: 'Compliment Melange Triple cheddar rape', nameEn: 'Compliment Triple Cheddar Blend Shredded Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574255356.jpg' },
 { departmentId: '13', id: '657', nameFr: 'Kraft Single Mozzarella', nameEn: 'Kraft Single Mozzarella',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006810000348.jpg' },
 { departmentId: '13', id: '658', nameFr:'Ptit Quebec Mozzarella et Cheddar rape', nameEn: 'Ptit Quebec Mozzarella Cheddar Shreds Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820047707.jpg' },
 { departmentId: '13', id: '659', nameFr:'Cracker Barrel Tex-Mex rape', nameEn: 'Cracker Barrel Tex Mex Shreds Cheese',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820047714.jpg' },
 { departmentId: '13', id: '660', nameFr:'Oasis Premium Jus Orange avec pulpe 1.65L', nameEn: 'Oasis Premium with pulpe Orange juice 1.65L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731108044.jpg' },
 { departmentId: '13', id: '661', nameFr:'Oasis Jus Orange et Mangue 1.75L', nameEn: 'Oasis Orange and Mango juice 1.75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006731102467.jpg' },
 { departmentId: '13', id: '662', nameFr:'Tropicana Jus Orange sans puple 1.54L', nameEn: 'Tropicana No Pulp Orange juice 1.54L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0004850020354.jpg' },
 { departmentId: '13', id: '663', nameFr:'Compliment Jus Orange avec puple 2.5L', nameEn: 'Compliment with Pulp Orange juice 2.5L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574255371.jpg' },
 { departmentId: '13', id: '664', nameFr:'Compliment Jus Orange sans puple 2.5L', nameEn: 'Compliment no Pulp Orange juice 2.5L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574255370.jpg' }, 
 { departmentId: '13', id: '665', nameFr:'Natrel Lait ecreme 0% 2L', nameEn: 'Natrel Skim milk 0% 2L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006442001032.jpg' }, 
 { departmentId: '13', id: '666', nameFr:'Margarine a huile olive', nameEn: 'Becel with olive oil Margarine',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001111500118.jpg' }, 
 { departmentId: '13', id: '667', nameFr:'Margarine molle original Becel', nameEn: 'Becel Original Margarine',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005995019010.jpg' },
 { departmentId: '13', id: '668', nameFr:'Margarine sans sel Becel', nameEn: 'Becel Salt free Margarine',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0001111500136.jpg' },
 { departmentId: '13', id: '669', nameFr:'Oeuf blanc de poules en liberte', nameEn: 'White Free Run Hens Egg',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006171901193.jpg' },
 { departmentId: '13', id: '670', nameFr:'Yop yogourt a boire a la vanille', nameEn: 'Yop Vanilla Drinkable yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692001227.jpg' },
 { departmentId: '13', id: '671', nameFr:'Yoplait Source yogourt a la vanille', nameEn: 'Yoplait Source Vanilla yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005692006066.jpg' },
 { departmentId: '13', id: '672', nameFr:'Riviera yogourt a la vanille 4x120gr', nameEn: 'Riviera Vanilla yogurt 4x120gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006491208550.jpg' },
 { departmentId: '13', id: '673', nameFr:'Astro yogourt au cappuccino 6%m.g', nameEn: 'Astro Cappuccino yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820044282.jpg' },
 { departmentId: '13', id: '674', nameFr:'Oikos yogourt grec a la banane', nameEn: 'Oikos Banana yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680018624.jpg' },
 { departmentId: '13', id: '675', nameFr:'Oikos yogourt grec au miel', nameEn: 'Oikos Honey yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680055245.jpg' },
 { departmentId: '13', id: '676', nameFr:'Oikos yogourt grec aux peches et mangues', nameEn: 'Oikos Peach Mango yogurt',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005680066482.jpg' },
 { departmentId: '13', id: '677', nameFr:'Compliment Batonnets de beurre non sales', nameEn: 'Compliment Unsalted Stick Butter',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574252236.jpg' },
 { departmentId: '13', id: '678', nameFr:'Compliment Batonnets de beurre sales', nameEn: 'Compliment Salted Stick Butter',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005574252237.jpg' },
 { departmentId: '13', id: '678', nameFr:'Amstrong Batonnets de fromage cheddar', nameEn: 'Amstrong Yellow Old Cheddar Cheese 210gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006112010140.jpg' },
 { departmentId: '13', id: '679', nameFr:'Lactancia Beurre a ail 125gr', nameEn: 'Lactancia Garlic butter 125gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006609612370.jpg' },
 { departmentId: '13', id: '680', nameFr:'Lactancia Beurre Premium Sale 250gr', nameEn: 'Lactancia Premium Salted butter 250gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006820030043.jpg' },
 { departmentId: '13', id: '681', nameFr:'Lactancia Beurre Sale 454gr', nameEn: 'Lactancia Salted butter 454gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006609612330.jpg' },
 { departmentId: '13', id: '682', nameFr:'Lactancia Beurre Sale Leger 454gr', nameEn: 'Lactancia Light Salted butter 454gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006609630023.jpg' },
 { departmentId: '13', id: '683', nameFr:'Blanc oeuf 500gr', nameEn: 'Naturegg 500gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006565100249.jpg' },
 { departmentId: '13', id: '684', nameFr:'Rise Kombucha Gingembre 414ml', nameEn: 'Rise Kombucha Ginger 414ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081876200001.jpg' },
 { departmentId: '13', id: '685', nameFr:'Rise Kombucha Citron 414ml', nameEn: 'Rise Kombucha Lemongrass 414ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081876200002.jpg' },
 { departmentId: '13', id: '686', nameFr:'Rise Kombucha Menthe 1L', nameEn: 'Rise Kombucha Mint 1L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0081876200104.jpg' },
 { departmentId: '13', id: '687', nameFr:'Rise Kombucha Hibiscue Rose 1L', nameEn: 'Rise Kombucha Hibiscue Rose 1L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/en/medium/0081876200036.jpg' },
 { departmentId: '13', id: '688', nameFr:'SoNice Amande Non sucre Bio 1,75L', nameEn: 'SoNice Almond Unsweetened Bio 1,75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062602708781.jpg' },
 { departmentId: '13', id: '689', nameFr:'SoFresh Avoine Original Non sucre 1,75L', nameEn: 'SoNice Unsweetened Original Oat 1,75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062602781102.jpg' }, 
 { departmentId: '13', id: '690', nameFr:'SoFresh Avoine a la vanille Non sucre 1,75L', nameEn: 'SoNice Unsweetened Vanilla Oat 1,75L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0062602781104.jpg' }, 
 { departmentId: '13', id: '691', nameFr:'Creme a cafe 10% 237ml', nameEn: '10% Ultra cream 237ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587212051.jpg' },
 { departmentId: '13', id: '692', nameFr:'Creme a cafe 10% 473ml', nameEn: '10% Ultra cream 473ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587222057.jpg' },
 { departmentId: '13', id: '693', nameFr:'Creme a cafe 10% 1L', nameEn: '10% Ultra cream 1L',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587212067.jpg' },
 { departmentId: '13', id: '694', nameFr:'Creme de table 15% 473ml', nameEn: '15% Ultra cream 473ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587223057.jpg' },
 { departmentId: '13', id: '695', nameFr:'Creme a fouetter 35% 473ml', nameEn: '35% Ultra cream 473ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587224057.jpg' },
 { departmentId: '13', id: '696', nameFr:'Creme a fouetter veritable 20%m.g 225gr', nameEn: '20%m.f. Whipped cream 225gr',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587216391.jpg' },
 { departmentId: '13', id: '697', nameFr:'Creme champetre 15% 473ml', nameEn: '15% country style cream 473ml',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0005587225057.jpg' },
 { departmentId: '13', id: '698', nameFr:'Creme fraiche Liberte', nameEn: 'Fresh cream Libete',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006568411613.jpg' },
 { departmentId: '13', id: '699', nameFr:'Sealtest Creme sure 14%', nameEn: 'Sealtest 14%m.f.Sour cream',  imageUrl:'https://az836796.vo.msecnd.net/media/image/product/fr/medium/0006442000627.jpg' },













]
