import { AppState } from './store'
import { Dispatch } from 'redux'
import {
  orderSubmitted,
  orderSubmitErrored,
  isSubmittingOrder,
} from './order.slice'
import { clearCart } from './cart.slice'

const ORDERING_ENDPOINT =
  'https://4p577sgni1.execute-api.us-east-1.amazonaws.com/default/ordering'

const API_KEY = 'G9NvUwfiWd12JGALOXCkg4dsAgnIMJif4KbEFfK6'

export const submitOrder = (finalize: () => void) => (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  const { order, cart } = getState()
  const cartItems = Array.from(
    cart.items.values()
  ).map(({ nameFr, quantity }) => ({ name: nameFr, quantity }))
  const data = {
    name: order.name || '',
    email: order.email || '',
    phoneNumber: order.phoneNumber || '',
    address: `${order.address1} ${order.address2 || ''}`.trim(),
    otherInfo: order.otherInfo || '',
    paymentMethod: order.paymentMethod || '',
    cart: cartItems,
  }

  const promise = fetch(ORDERING_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'x-api-key': API_KEY },
  }).then(
    () => {
      dispatch(orderSubmitted())
      dispatch(clearCart())
      finalize()
    },
    (error) => dispatch(orderSubmitErrored(error))
  )

  dispatch(isSubmittingOrder())

  return promise
}
