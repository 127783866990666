import React, { useState, ChangeEvent, useRef } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  openDrawer,
  openCartCard,
  closeCartCard,
  selectIsCartCardOpen,
  openSearchCard,
} from '../../services/modals.slice'
import { selectCartItemsCount } from '../../services/cart.slice'

import { CartCard } from '../CartCard/CartCard'
import { SearchCard } from '../SearchCard/SearchCard'

export const useMenuBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    icon: {
      width: 50,
      marginRight: 10,
    },
    logo: {
      width: '12em',
    },
    '@media(max-width: 600px)': {
      icon: {
        width: '2em',
      },
      logo: {
        display: 'none',
      },
      menuButton: {
        marginRight: theme.spacing(0),
      },
    },
  })
)

export const MenuBar: React.FunctionComponent = () => {
  const classes = useMenuBarStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onMenuButtonClick = () => dispatch(openDrawer())
  const isCartCardOpen = useSelector(selectIsCartCardOpen)
  const onCartClick = () =>
    dispatch(isCartCardOpen ? closeCartCard() : openCartCard())
  const articlesInCart = useSelector(selectCartItemsCount)
  const [searchQuery, setSearchQuery] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)

  const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(openSearchCard())
    setSearchQuery(event.target.value)
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={onMenuButtonClick}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={'/mduvil-basket.png'}
            alt="Marche du Village - CDN Icon"
            className={classes.icon}
          />
          <img
            src={'/mduvil-logo.png'}
            alt="Marche du Village - CDN"
            className={classes.logo}
          />
          <div className={classes.search}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              placeholder={t('Search…')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputRef={searchInputRef}
              inputProps={{ 'aria-label': t('Search…') }}
              onChange={onSearchQueryChange}
            />
          </div>
          <span className={classes.grow} />
          <IconButton
            aria-label={t('show {{ number }} articles', {
              number: articlesInCart,
            })}
            color="inherit"
            onClick={onCartClick}
          >
            <Badge badgeContent={articlesInCart} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <CartCard />
      <SearchCard query={searchQuery} searchInputRef={searchInputRef} />
    </>
  )
}
