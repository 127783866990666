import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useDepartmentStyles } from './Department.styles'
import { useParams } from 'react-router'
import { ALL_DEPARTMENTS } from '../../services/departments'
import { ALL_ARTICLES } from '../../services/article'
import { useTranslation } from 'react-i18next'
import { Item } from '../Item/Item'
import { closeDrawer } from '../../services/modals.slice'
import { useDispatch } from 'react-redux'

export const Department: React.FunctionComponent = () => {
  const { departmentId } = useParams()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const classes = useDepartmentStyles()

  useEffect(() => {
    dispatch(closeDrawer())
  })

  const department = ALL_DEPARTMENTS.find(
    (department) => department.id === departmentId
  )
  if (!departmentId || !department) {
    return (
      <main>
        <Typography variant="h3" className={classes.heading}>
          {t('Unknown department')}
        </Typography>
      </main>
    )
  }
  const items = ALL_ARTICLES.filter(
    (articles) => articles.departmentId === departmentId
  )

  return (
    <main className={classes.root}>
      <Typography variant="h3" noWrap className={classes.heading}>
        {i18n.language === 'en'
          ? department.departmentNameEn
          : department.departmentNameFr}
      </Typography>
      <Grid container wrap="wrap" spacing={2} className={classes.articleGrid}>
        {items.map((item) => (
          <Grid item xs="auto" key={item.id}>
            <Item
              articleId={item.id}
              articleName={i18n.language === 'en' ? item.nameEn : item.nameFr}
              imageUrl={item.imageUrl}
            />
          </Grid>
        ))}
      </Grid>
    </main>
  )
}
