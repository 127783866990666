import {
  createStore,
  Action,
  combineReducers,
  Reducer,
  applyMiddleware,
} from 'redux'
import { modalReducerConfig, ModalModel } from './modals.slice'
import { composeWithDevTools } from 'redux-devtools-extension'
import { cartReducerConfig, CartModel } from './cart.slice'
import { enableMapSet } from 'immer'
import thunk from 'redux-thunk'
import { orderReducerConfig, OrderModel } from './order.slice'

enableMapSet()

export interface ActionWithPayload<P> extends Action<string> {
  payload: P
}

export interface ReducerConfig<T> {
  name: string
  reducer: Reducer<T>
}

export interface AppState {
  order: OrderModel
  cart: CartModel
  modal: ModalModel
}

export const store = createStore(
  combineReducers({
    [modalReducerConfig.name]: modalReducerConfig.reducer,
    [cartReducerConfig.name]: cartReducerConfig.reducer,
    [orderReducerConfig.name]: orderReducerConfig.reducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
)
