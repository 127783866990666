import React, { FunctionComponent } from 'react'
import { useAppFooterStyles } from './AppFooter.styles'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

export const AppFooter: FunctionComponent = () => {
  const classes = useAppFooterStyles()
  return (
    <footer className={classes.root}>
      <Typography variant="subtitle1">
        Marché Du Village - Côte des Neiges
      </Typography>
      <Typography variant="subtitle2">
        <Button
          href="https://www.google.com/maps/place/March%C3%A9+Du+Village+C%C3%B4te+des+Neiges/@45.4977287,-73.624995,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc919ee257859c3:0xbcaaa03b823347ae!8m2!3d45.497725!4d-73.622801"
          target="_blank"
          disableRipple
          disableFocusRipple
          className={classes.link}
        >
          5415 Avenue Gatineau, Montréal, QC H3T 1X4, Canada
        </Button>
      </Typography>
      <Typography variant="subtitle2">
        <Button
          href="tel:5147351366"
          disableRipple
          disableFocusRipple
          className={classes.link}
        >
          (514) 735 - 3611
        </Button>
      </Typography>
      <IconButton
        disableRipple
        disableFocusRipple
        href="https://www.facebook.com/marcheduvillagecotedesneiges/"
        target="_blank"
        style={{ color: '#1976d2' }}
        className={classes.link}
      >
        <FacebookIcon fontSize="large" />
      </IconButton>
    </footer>
  )
}
