import { createSlice } from '@reduxjs/toolkit'
import { ActionWithPayload, ReducerConfig, AppState } from './store'
import { Article, ALL_ARTICLES } from './article'

export interface CartModel {
  items: ReadonlyMap<string, CartItem>
}

export interface AddToCartPayload {
  articleId: string
  quantity: number
}

export interface UpdateInCartPayload {
  articleId: string
  quantity: number
}

export interface CartItem extends Article {
  quantity: number
}

const initialState: CartModel = {
  items: new Map(),
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: ActionWithPayload<AddToCartPayload>) => {
      const item = action.payload
      updateItemQuantity(item.articleId, item.quantity, state.items, true)
    },
    updateInCart: (state, action: ActionWithPayload<UpdateInCartPayload>) => {
      const item = action.payload
      updateItemQuantity(item.articleId, item.quantity, state.items)
    },
    clearCart: (state, action: ActionWithPayload<void>) => {
      state.items = new Map()
    },
  },
})

const updateItemQuantity = (
  articleId: string,
  quantity: number,
  items: Map<string, CartItem>,
  increment: boolean = false
) => {
  const article = ALL_ARTICLES.find((article) => article.id === articleId)
  if (!article) {
    return
  }
  const itemFound = items.get(articleId)
  if (itemFound && increment) {
    quantity += itemFound.quantity
  }

  items.delete(articleId)
  if (quantity > 0 || increment) {
    items.set(articleId, { ...article, quantity })
  }
}

export const selectCartItemsCount = (state: AppState) =>
  Array.from(state?.cart?.items.values() ?? []).length

export const selectCartItems = (state: AppState) =>
  Array.from(state?.cart?.items.values()).sort((a, b) => {
    const byDept = Number(a.departmentId) - Number(b.departmentId)
    if (byDept === 0) {
      return Number(a.id) - Number(b.id)
    }
    return byDept
  })

export const { addToCart, updateInCart, clearCart } = cartSlice.actions
export const cartReducerConfig: ReducerConfig<CartModel> = {
  name: cartSlice.name,
  reducer: cartSlice.reducer,
}
