import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { selectCartItems } from '../../services/cart.slice'

import { useCartCardStyles } from './CartCard.styles'
import Button from '@material-ui/core/Button'
import { ListCartItem } from '../ListCartItem/ListCartItem'
import {
  closeCartCard,
  selectIsCartCardOpen,
} from '../../services/modals.slice'

export const CartCard = () => {
  const classes = useCartCardStyles()
  const isOpen = useSelector(selectIsCartCardOpen)
  const cartItems = useSelector(selectCartItems)
  const dispatch = useDispatch()
  const onCheckoutClick = () => dispatch(closeCartCard())
  const { t } = useTranslation()
  if (!isOpen) {
    return null
  }
  const onClickAway = () => dispatch(closeCartCard())

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label={t('shopping cart')} className={classes.avatar}>
              <ShoppingCartIcon />
            </Avatar>
          }
          action={
            <Button
              component={NavLink}
              to="/checkout"
              variant="outlined"
              color="secondary"
              onClick={onCheckoutClick}
            >
              {t('Checkout')}
            </Button>
          }
          title={t('My Shopping Cart')}
        />
        <CardContent>
          <ListCartItem items={cartItems} />
        </CardContent>
      </Card>
    </ClickAwayListener>
  )
}
