import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useOrderCompleteStyles } from './OrderComplete.styles'
import { useTranslation } from 'react-i18next'

export const OrderComplete: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const classes = useOrderCompleteStyles()

  return (
    <main className={classes.root}>
      <img
        alt="cheese and tomatoes"
        src="https://scontent-otp1-1.xx.fbcdn.net/v/t1.0-9/12743498_499708620201618_7454257291956827951_n.jpg?_nc_cat=107&_nc_sid=110474&_nc_ohc=kVymp1uPoqIAX-jcjA1&_nc_ht=scontent-otp1-1.xx&oh=727c9fd8d22feddca5eed73d3ee543f4&oe=5EAC355C"
      />
      <Typography variant="h3" className={classes.heading}>
        {t('Thank you for your order!')}
      </Typography>
      <Typography variant="subtitle1" className={classes.body}>
        {t(
          'We have recieved your order Once we have verified the items in your cart, we will contact you with the final bill and delivery times'
        )}
      </Typography>
    </main>
  )
}
