import { makeStyles, Theme, createStyles, fade } from '@material-ui/core/styles'

export const useItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '15em',
      height: '24em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: fade(theme.palette.common.white, 0.85),
    },
    title: {
      textTransform: 'capitalize',
      textAlign: 'center',
    },
    pos: {
      marginBottom: 12,
    },
    image: {
      height: 160,
      width: 160,
    },
    actions: {
      flexDirection: 'column',
    },
    input: {
      maxWidth: '43px',
      '& input': { textAlign: 'center' },
      '& ::-webkit-inner-spin-button': {
        display: 'none',
      },
    },
  })
)
