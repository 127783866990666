import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useListCartItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    listItem: {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    itemName: {
      marginRight: 10,
    },
    itemType: {
      marginRight: 10,
    },
  })
)
