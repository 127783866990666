import { makeStyles, Theme, createStyles } from '@material-ui/core'

export const useAppFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      marginTop: 'auto',
    },
    link: {
      textTransform: 'none',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
  })
)
