import { createSlice } from '@reduxjs/toolkit'
import { ActionWithPayload, ReducerConfig, AppState } from './store'

export interface ModalModel {
  isDrawerOpen: boolean
  isCartCardOpen: boolean
  isSearchCardOpen: boolean
}

const initialState: ModalModel = {
  isDrawerOpen: false,
  isCartCardOpen: false,
  isSearchCardOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openDrawer: (state, action: ActionWithPayload<void>) => {
      state.isDrawerOpen = true
    },
    closeDrawer: (state, action: ActionWithPayload<void>) => {
      state.isDrawerOpen = false
    },
    openCartCard: (state, action: ActionWithPayload<void>) => {
      state.isCartCardOpen = true
    },
    closeCartCard: (state, action: ActionWithPayload<void>) => {
      state.isCartCardOpen = false
    },
    openSearchCard: (state, action: ActionWithPayload<void>) => {
      state.isSearchCardOpen = true
    },
    closeSearchCard: (state, action: ActionWithPayload<void>) => {
      state.isSearchCardOpen = false
    },
  },
})

export const selectIsDrawerOpen = (state: AppState) =>
  state?.modal?.isDrawerOpen

export const selectIsCartCardOpen = (state: AppState) =>
  state?.modal?.isCartCardOpen

export const selectIsSearchCardOpen = (state: AppState) =>
  state?.modal?.isSearchCardOpen

export const {
  openDrawer,
  closeDrawer,
  openCartCard,
  closeCartCard,
  openSearchCard,
  closeSearchCard,
} = modalSlice.actions
export const modalReducerConfig: ReducerConfig<ModalModel> = {
  name: modalSlice.name,
  reducer: modalSlice.reducer,
}
