import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useDepartmentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 20,
      marginLeft: 100,
    },
    heading: {
      marginBottom: 100,
      color: theme.palette.primary.main,
    },
    articleGrid: {},
    '@media(max-width: 600px)': {
      root: {
        margin: 20,
      },
      heading: {
        marginBottom: 50,
        fontSize: '2rem',
      },
      articleGrid: {
        justifyContent: 'center',
      },
    },
  })
)
