import React, { FunctionComponent, ChangeEvent, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { useCheckoutStyles } from './Checkout.styles'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { ListCartItem } from '../ListCartItem/ListCartItem'

import { clearCart, selectCartItems } from '../../services/cart.slice'
import {
  setInfo,
  selectName,
  selectEmail,
  selectAddress1,
  selectAddress2,
  selectPhoneNumber,
  selectOtherInfo,
} from '../../services/order.slice'
import { submitOrder } from '../../services/order.thunk'
import { useHistory } from 'react-router-dom'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'

enum PaymentMethod {
  CreditCard = 'Carte de credit',
  ETransfer = 'Virement interact',
  Cash = 'Comptant',
}

export const Checkout: FunctionComponent = () => {
  const classes = useCheckoutStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const formRef = useRef<HTMLFormElement | null>(null)
  const [showCCCheck, setShowCCCheck] = useState(false)
  const [isCCChecked, setCCChecked] = useState(false)
  const dispatch = useDispatch()
  const onRemoveItemsClick = () => dispatch(clearCart())
  const cartItems = useSelector(selectCartItems)
  const name = useSelector(selectName)
  const email = useSelector(selectEmail)
  const address1 = useSelector(selectAddress1)
  const address2 = useSelector(selectAddress2)
  const phoneNumber = useSelector(selectPhoneNumber)
  const otherInfo = useSelector(selectOtherInfo)
  const onSubmitClick = () => {
    dispatch(submitOrder(() => history.push('/completed')))
  }

  const setInfoFor = (key: string) => (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(setInfo({ [key]: event.target.value }))

  const onNameFieldChange = setInfoFor('name')
  const onEmailFieldChange = setInfoFor('email')
  const onAddress1FieldChange = setInfoFor('address1')
  const onAddress2FieldChange = setInfoFor('address2')
  const onPhoneNumberFieldChange = setInfoFor('phoneNumber')
  const onOtherInfoFieldChange = setInfoFor('otherInfo')
  const onPaymentMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    const paymentMethod = event.target.value
    dispatch(setInfo({ paymentMethod }))
    setShowCCCheck(paymentMethod === PaymentMethod.CreditCard)
  }
  const onCCCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCCChecked(event.target.checked)
  }

  return (
    <form className={classes.root} ref={formRef}>
      <section className={classes.cart}>
        <Typography variant="h5" noWrap>
          {t('Cart')}
        </Typography>
        <ListCartItem items={cartItems} />
        <Divider />
        <Button
          className={classes.removeButton}
          disabled={!cartItems.length}
          onClick={onRemoveItemsClick}
        >
          {t('Remove all items')}
        </Button>
        <Typography variant="body2">
          {t('Product details, special instructions, comments:')}
        </Typography>
        <TextField
          id="other-info"
          multiline
          label={t('Notes')}
          style={{ marginLeft: 0, width: '100%' }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={otherInfo}
          variant="outlined"
          onChange={onOtherInfoFieldChange}
        />
      </section>
      <section className={classes.paymentMethod}>
        <Typography variant="h5">{t('Payment Method')}*</Typography>
        <RadioGroup
          aria-label={t('Payment Method')}
          name="paymentmethod"
          onChange={onPaymentMethodChange}
          className={classes.paymentMethodGroup}
        >
          <FormControlLabel
            value={PaymentMethod.ETransfer}
            control={<Radio required />}
            label={t('e-Transfer')}
          />
          <FormControlLabel
            value={PaymentMethod.CreditCard}
            control={<Radio />}
            label={t('Credit Card')}
          />
          {showCCCheck && (
            <FormControlLabel
              control={
                <Checkbox
                  name="ccCheck"
                  className={classes.ccCheck}
                  checked={isCCChecked}
                  onChange={onCCCheckboxChange}
                />
              }
              label={t(
                'I authorize Marche du Village to bill this order to my credit card'
              )}
            />
          )}
          <FormControlLabel
            value={PaymentMethod.Cash}
            control={<Radio />}
            label={t('Cash')}
          />
        </RadioGroup>
      </section>
      <section className={classes.clientInfo}>
        <Typography variant="h5">
          {t('Please provide your information')}
        </Typography>

        <TextField
          required
          id="name"
          label={t('Name')}
          value={name}
          onChange={onNameFieldChange}
        />
        <TextField
          required
          id="email"
          label={t('Email Address')}
          inputProps={{ type: 'email' }}
          value={email}
          onChange={onEmailFieldChange}
        />
        <TextField
          required
          id="address1"
          label={t('Address 1')}
          value={address1}
          onChange={onAddress1FieldChange}
        />
        <TextField
          id="address2"
          label={t('Address 2')}
          value={address2}
          onChange={onAddress2FieldChange}
        />
        <TextField
          required
          id="phone"
          label={t('Phone number')}
          inputProps={{ type: 'tel' }}
          value={phoneNumber}
          onChange={onPhoneNumberFieldChange}
        />
      </section>
      <Divider />
      <Button
        className={classes.submitButton}
        variant="contained"
        color="primary"
        disabled={
          cartItems.length < 1 ||
          !formRef.current?.checkValidity() ||
          !((showCCCheck && isCCChecked) || !showCCCheck)
        }
        onClick={onSubmitClick}
      >
        {t('Submit Order')}
      </Button>
    </form>
  )
}
