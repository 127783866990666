import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useOrderCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 20,
      marginLeft: 100,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '50em',
    },
    heading: {
      marginBottom: 50,
      color: theme.palette.primary.main,
    },
    body: {
      color: theme.palette.secondary.main,
    },
  })
)
