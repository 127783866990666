import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
    },
    headerImage: {
      width: '100%',
    },
    heading: {
      marginBottom: 50,
      color: theme.palette.primary.main,
    },
    body: {
      textAlign: 'initial',
      color: theme.palette.secondary.main,
      marginLeft: 20,
    },
  })
)
