import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

export const useCartCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: '5em',
      right: '1em',
      zIndex: 99,
      '& .MuiCardHeader-action': {
        marginTop: 2,
        marginRight: 0,
        marginLeft: 10,
      },
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
)
